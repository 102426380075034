import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Alert, Navbar } from "react-bootstrap";
import { createCode } from '../api';
import { useLoading } from '../contexts/LoadingContext';

const Testing = () => {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(false);
  const { setLoading } = useLoading();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    setLoading(true);

    try {
      const response = await createCode(email);

      if (typeof response != "undefined") {
        setStatus(200);
      } else {
        setError(true);
      }
    } catch (error) {
      console.error("Error submitting email:", error);
      setError(true);
    }
    setLoading(false);
  };

  return (
    <div>
      <Navbar bg="light" variant="light" expand="lg" style={{'borderBottom':'1px solid rgba(0,0,0,.1)','padding':'10px 15px'}}>
        <Container fluid>
          <Navbar.Brand>
            Cora Science Testing
          </Navbar.Brand>
        </Container>
      </Navbar>
      <div style={{'padding': '30px 15px', 'background': 'rgba(203,203,210,.15)'}}>
        <Container fluid style={{ 'height': 'calc(100vh - 123px)', 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center'}}>
          <Row className="justify-content-md-center" style={{ 'width': '50%' }}>
            <Col  xs={12} md={12}>
              {status === 200 ? (
                <Alert variant="success">
                  Please check your email for your secure submission link.
                </Alert>
              ) : (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Customer Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="test@example.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {error && (
                      <Form.Text className="text-danger">
                        Email is invalid or not an existing customer. Please email{" "}
                        <a href="mailto:lab@corascience.com">lab@corascience.com</a> to be
                        onboarded.
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Email Secure Link
                  </Button>
                </Form>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Testing;
