import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import Cookies from "universal-cookie";
import sha256 from "crypto-js/sha256";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./Home.css";
import "bootstrap/dist/css/bootstrap.min.css";
import BaseTable from "../components/Tables/BaseTable";
import BillingAccountsTable from "../components/Tables/BillingAccountsTable";
import ContactsTable from "../components/Tables/ContactsTable";
import CustomersTable from "../components/Tables/CustomersTable";
import WorkOrdersTable from "../components/Tables/WorkOrdersTable";
import TestTemplatesTable from "../components/Tables/TestTemplatesTable";
import SamplesTable from "../components/Tables/SamplesTable";
import MatricesTable from "../components/Tables/MatricesTable";
import RulesTable from "../components/Tables/RulesTable";
import ContactsForm from "../components/Forms/ContactsForm";
import BaseForm from "../components/Forms/BaseForm";
import BillingAccountsForm from "../components/Forms/BillingAccountsForm";
import CustomersForm from "../components/Forms/CustomersForm";
import WorkOrdersForm from "../components/Forms/WorkOrdersForm";
import TestTemplatesForm from "../components/Forms/TestTemplatesForm";
import SamplesForm from "../components/Forms/SamplesForm";
import MatricesForm from "../components/Forms/MatricesForm";
import RulesForm from "../components/Forms/RulesForm";
import BulkTestUpload from "../components/BulkTestUpload";
import GenerateInvoice from "../components/GenerateInvoice";
import { resizeFile, upperCaseWords, encrypt } from "../utils";
import {
  createAddress,
  fetchAddresses,
  updateAddress,
  deleteAddress,
  createContact,
  fetchContacts,
  updateContact,
  deleteContact,
  createBillingAccount,
  fetchBillingAccounts,
  updateBillingAccount,
  deleteBillingAccount,
  createCustomer,
  fetchCustomers,
  updateCustomer,
  deleteCustomer,
  createGroup,
  fetchGroups,
  updateGroup,
  deleteGroup,
  createMatrix,
  fetchMatrices,
  updateMatrix,
  deleteMatrix,
  createRule,
  fetchRules,
  updateRule,
  deleteRule,
  createWorkOrder,
  fetchWorkOrders,
  updateWorkOrder,
  deleteWorkOrder,
  createSample,
  fetchSamples,
  updateSample,
  deleteSample,
  createImage,
  fetchImagesForSample,
  deleteImage,
  createTestTemplate,
  fetchTestTemplates,
  updateTestTemplate,
  deleteTestTemplate,
  createTest,
  fetchTestsForSample,
  updateTest,
  deleteTest,
} from "../api";

const cookies = new Cookies();

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      models: {
        samples: [],
        work_orders: [],
        test_templates: [],
        groups: [],
        matrices: [],
        rules: [],
        customers: [],
        billing_accounts: [],
        contacts: [],
        addresses: [],
      },
      selected_model: null,
      code: "",
      auth: false,
      is_loading: false,
      show_delete_modal: false,
      delete_code: "",
    };
  }

  setStateForKey(key, value) {
    this.setState({ [key]: value });
  }

  componentDidMount = async () => {
    this.setStateForKey("is_loading", true);
    if (
      cookies.get("cora_science_admin") &&
      sha256(cookies.get("cora_science_admin")).toString() ===
        "26514fcfabb61114a12bfbfa876e863e17538bb3f66fe1fabc0e70d6b22f3ae4"
    ) {
      try {
        const [
          samples,
          work_orders,
          test_templates,
          customers,
          billing_accounts,
          contacts,
          addresses,
          groups,
          matrices,
          rules,
        ] = await Promise.all([
          fetchSamples(),
          fetchWorkOrders(),
          fetchTestTemplates(),
          fetchCustomers(),
          fetchBillingAccounts(),
          fetchContacts(),
          fetchAddresses(),
          fetchGroups(),
          fetchMatrices(),
          fetchRules(),
        ]);

        this.setStateForKey("models", {
          samples,
          work_orders,
          test_templates,
          customers,
          billing_accounts,
          contacts,
          addresses,
          groups,
          matrices,
          rules,
        });
        this.setStateForKey("selected_tab", "samples");
        this.setStateForKey("auth", true);
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    } else {
      this.setStateForKey("auth", false);
    }
    this.setStateForKey("is_loading", false);
  };

  uploadImage = async (event) => {
    if (event.target.files[0]) {
      if (event.target.files[0].size > 1000000) {
        alert("File must be less than 1MB!");
      } else {
        const base64 = await resizeFile(event.target.files[0]);
        const selected_model = { ...this.state.selected_model };
        let image = selected_model[event.target.name];
        image["base_64"] = base64;
        selected_model[event.target.name] = image;
        this.setStateForKey("selected_model", selected_model);
      }
    }
  };

  deleteImage = async (image, name) => {
    this.setStateForKey("is_loading", true);
    const selected_model = { ...this.state.selected_model };
    selected_model[name] = {
      id: null,
      base_64: null,
    };
    this.setStateForKey("selected_model", selected_model);
    await deleteImage(image);
    this.setStateForKey("is_loading", false);
  };

  addTestToSample = async (event) => {
    const test = {
      id: null,
      sample_id: this.state.selected_model.id,
      test_template_id: "",
      metadata: "",
    };
    const selected_model = { ...this.state.selected_model };
    let tests = selected_model["tests"];
    tests.push(test);
    selected_model["tests"] = tests;
    this.setStateForKey("selected_model", selected_model);
  };

  handleCode = (event) => {
    this.setStateForKey("code", event.target.value);
  };

  submitCode = (event) => {
    this.setStateForKey("code", this.state.code);
    cookies.set("cora_science_admin", this.state.code, { path: "/" });
    window.location.reload(false);
  };

  handleChange = (event, index = null, nested_key = null) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    switch (target.type) {
      case "checkbox":
        value = target.checked;
        break;
      case "select-one":
        if (
          ![
            "active",
            "job_status",
            "invoice_status",
            "status",
            "revision_number",
            "group",
            "matrix",
            "role",
            "published",
            "turnaround_time",
            "tests",
            "payment_method",
          ].includes(name)
        ) {
          value = +target.value;
        } else {
          value = target.value;
        }
        break;
      default:
        value = target.value;
    }
    const selected_model = { ...this.state.selected_model };
    if (index !== null && nested_key !== null) {
      let nested_object = selected_model[name];
      nested_object[index][nested_key] = value;
      selected_model[name] = nested_object;
    } else if (nested_key !== null) {
      let nested_object = selected_model[nested_key];
      nested_object[name] = value;
      selected_model[nested_key] = nested_object;
    } else {
      selected_model[name] = value;
    }
    this.setStateForKey("selected_model", selected_model);
  };

  setSelectedTab = (tab) => {
    this.setStateForKey("selected_tab", tab);
  };

  setSelectedModel = async (event, name, index, id = null) => {
    this.setStateForKey("is_loading", true);
    event.preventDefault();
    const models = [...this.state.models[name]];
    let selected_model = {};

    if (index > models.length) {
      for (var key in models[0]) selected_model[key] = "";
      selected_model["is_update"] = false;
      if (name === "customers") {
        selected_model["active"] = true;
      } else if (name === "work_orders") {
        selected_model["invoice_status"] = "GENERATED";
        selected_model["job_status"] = "RECEIVED";
        selected_model["payment_method"] = null;
        selected_model["received_at"] = Date.now();
      } else if (name === "samples") {
        selected_model["metadata"] = {
          status: "SAMPLE RECEIVED",
          group: this.state.models["groups"][0]["name"].toUpperCase(),
          matrix: this.state.models["matrices"]
            .filter((matrix) =>
              this.state.models["groups"].find(
                (group) =>
                  group.id === matrix.group_id &&
                  group.name.toUpperCase() ===
                    this.state.models["groups"][0]["name"].toUpperCase()
              )
            )[0]
            .name.toUpperCase(),
          name: "",
          customer_lot_number: "",
          sample_description: "",
          turnaround_time: "STANDARD",
          completed_at: Date.now(),
          issued_at: Date.now(),
          intake_notes: "",
          lab_notes: "",
          qa_notes: "",
          for_release: false,
          revision_number: "00",
          additional_report_notes: "N/A",
          revision_history: "rev 00 - Initial release.",
        };
        selected_model["image_one"] = {
          id: null,
          base_64: null,
        };
        selected_model["image_two"] = {
          id: null,
          base_64: null,
        };
        selected_model["tests"] = [];
      } else if (name == "rules") {
        selected_model["customer_ids"] = [];
        selected_model["test_template_ids"] = [];
      }
    } else {
      selected_model =
        id !== null ? models.find((model) => model.id === id) : models[index];
      selected_model["is_update"] = true;

      if (name === "samples") {
        const images = await fetchImagesForSample(selected_model);
        // TODO: Clean up - this is all garbage due to last minute changes
        selected_model["image_one"] = {
          id: images.length > 0 ? images[0]["id"] : null,
          base_64:
            images.length > 0 ? images[0]["base_64"].replaceAll('"', "") : null,
        };
        selected_model["image_two"] = {
          id: images.length > 1 ? images[1]["id"] : null,
          base_64:
            images.length > 1 ? images[1]["base_64"].replaceAll('"', "") : null,
        };
        if (!("completed_at" in selected_model["metadata"])) {
          selected_model["metadata"]["completed_at"] = Date.now();
        }
        if (!("issued_at" in selected_model["metadata"])) {
          selected_model["metadata"]["issued_at"] = Date.now();
        }
        if (!("additional_report_notes" in selected_model["metadata"])) {
          selected_model["metadata"]["additional_report_notes"] = "N/A";
        }
        if (!("revision_history" in selected_model["metadata"])) {
          selected_model["metadata"]["revision_history"] =
            "rev 00 - Initial release.";
        }
        selected_model["tests"] = await fetchTestsForSample(selected_model);
      }
    }
    this.setStateForKey("selected_model", selected_model);
    this.setStateForKey("is_loading", false);
  };

  resetSelectedModel = () => {
    this.setStateForKey("selected_model", null);
  };

  createModel = async () => {
    this.setStateForKey("is_loading", true);
    const models = { ...this.state.models };
    let response;
    switch (this.state.selected_tab) {
      case "addresses":
        const address = { ...this.state.selected_model };
        response = await createAddress(address);
        address["id"] = response["id"];
        address["created_at"] = new Date().getTime();
        address["updated_at"] = new Date().getTime();
        delete address["is_update"];
        models["addresses"].unshift(address);
        break;
      case "contacts":
        const contact = { ...this.state.selected_model };
        response = await createContact(contact);
        contact["id"] = response["id"];
        contact["created_at"] = new Date().getTime();
        contact["updated_at"] = new Date().getTime();
        delete contact["is_update"];
        models["contacts"].unshift(contact);
        break;
      case "billing_accounts":
        const billing_account = { ...this.state.selected_model };
        response = await createBillingAccount(billing_account);
        billing_account["id"] = response["id"];
        billing_account["created_at"] = new Date().getTime();
        billing_account["updated_at"] = new Date().getTime();
        delete billing_account["is_update"];
        models["billing_accounts"].unshift(billing_account);
        break;
      case "customers":
        const customer = { ...this.state.selected_model };
        response = await createCustomer(customer);
        customer["id"] = response["id"];
        customer["created_at"] = new Date().getTime();
        customer["updated_at"] = new Date().getTime();
        delete customer["is_update"];
        models["customers"].unshift(customer);
        break;
      case "work_orders":
        const work_order = { ...this.state.selected_model };
        response = await createWorkOrder(work_order);
        work_order["id"] = response["id"];
        work_order["created_at"] = new Date().getTime();
        work_order["updated_at"] = new Date().getTime();
        delete work_order["is_update"];
        models["work_orders"].unshift(work_order);
        break;
      case "samples":
        const sample = { ...this.state.selected_model };
        for (let date_field of [
          "received_at",
          "due_at",
          "completed_at",
          "issued_at",
        ]) {
          if (typeof sample["metadata"][date_field] === "number") {
            sample["metadata"][date_field] = new Date(
              sample["metadata"][date_field]
            )
              .toISOString()
              .split("T")[0];
          }
        }
        response = await createSample(sample);
        sample["id"] = response["id"];
        sample["created_at"] = new Date().getTime();
        sample["updated_at"] = new Date().getTime();
        delete sample["is_update"];
        if (
          sample["image_one"]["base_64"] === null &&
          sample["image_one"]["id"] !== null
        ) {
          response = await createImage(sample["image_one"]);
          sample["image_one"]["id"] = null;
        }
        if (
          sample["image_two"]["base_64"] === null &&
          sample["image_two"]["id"] !== null
        ) {
          response = await createImage(sample["image_two"]);
          sample["image_two"]["id"] = null;
        }
        if (
          sample["image_one"]["base_64"] !== null &&
          sample["image_one"]["id"] === null
        ) {
          response = await createImage({
            ...sample["image_one"],
            sample_id: sample["id"],
          });
          sample["image_one"]["id"] = response["id"];
        }
        if (
          sample["image_two"]["base_64"] !== null &&
          sample["image_two"]["id"] === null
        ) {
          response = await createImage({
            ...sample["image_two"],
            sample_id: sample["id"],
          });
          sample["image_two"]["id"] = response["id"];
        }
        if (sample["test_internal_ids"].length == 0) {
          sample["test_internal_ids"] = [];
        }
        models["samples"].unshift(sample);
        break;
      case "test_templates":
        const test_template = { ...this.state.selected_model };
        response = await createTestTemplate(test_template);
        test_template["id"] = response["id"];
        test_template["created_at"] = new Date().getTime();
        test_template["updated_at"] = new Date().getTime();
        delete test_template["is_update"];
        models["test_templates"].unshift(test_template);
        break;
      case "groups":
        const group = { ...this.state.selected_model };
        response = await createGroup(group);
        group["id"] = group["id"];
        group["created_at"] = new Date().getTime();
        group["updated_at"] = new Date().getTime();
        delete group["is_update"];
        models["groups"].unshift(group);
        break;
      case "matrices":
        const matrix = { ...this.state.selected_model };
        response = await createMatrix(matrix);
        matrix["id"] = response["id"];
        matrix["created_at"] = new Date().getTime();
        matrix["updated_at"] = new Date().getTime();
        delete matrix["is_update"];
        models["matrices"].unshift(matrix);
        break;
      case "rules":
        const rule = { ...this.state.selected_model };
        response = await createRule(rule);
        rule["id"] = response["id"];
        rule["created_at"] = new Date().getTime();
        rule["updated_at"] = new Date().getTime();
        delete rule["is_update"];
        models["rules"].unshift(rule);
        break;
      default:
        console.log("Default case");
    }
    this.setStateForKey("models", models);
    this.resetSelectedModel();
    this.setStateForKey("is_loading", false);
  };

  updateModel = async () => {
    this.setStateForKey("is_loading", true);
    const models = { ...this.state.models };
    let response;

    switch (this.state.selected_tab) {
      case "addresses":
        const address = { ...this.state.selected_model };
        await updateAddress(address);
        delete address["is_update"];
        models["addresses"].splice(
          models["addresses"].findIndex((model) => model.id === address.id),
          1,
          address
        );
        break;
      case "contacts":
        const contact = { ...this.state.selected_model };
        await updateContact(contact);
        delete contact["is_update"];
        models["contacts"].splice(
          models["contacts"].findIndex((model) => model.id === contact.id),
          1,
          contact
        );
        break;
      case "billing_accounts":
        const billing_account = { ...this.state.selected_model };
        await updateBillingAccount(billing_account);
        delete billing_account["is_update"];
        models["billing_accounts"].splice(
          models["billing_accounts"].findIndex(
            (model) => model.id === billing_account.id
          ),
          1,
          billing_account
        );
        break;
      case "customers":
        const customer = { ...this.state.selected_model };
        await updateCustomer(customer);
        delete customer["is_update"];
        models["customers"].splice(
          models["customers"].findIndex((model) => model.id === customer.id),
          1
        );
        models["customers"].push(customer);
        break;
      case "work_orders":
        const work_order = { ...this.state.selected_model };
        await updateWorkOrder(work_order);
        delete work_order["is_update"];
        models["work_orders"].splice(
          models["work_orders"].findIndex(
            (model) => model.id === work_order.id
          ),
          1,
          work_order
        );
        break;
      case "samples":
        const sample = { ...this.state.selected_model };
        for (let date_field of [
          "received_at",
          "due_at",
          "completed_at",
          "issued_at",
        ]) {
          if (typeof sample["metadata"][date_field] === "number") {
            sample["metadata"][date_field] = new Date(
              sample["metadata"][date_field]
            )
              .toISOString()
              .split("T")[0];
          }
        }
        await updateSample(sample);
        if (
          sample["image_one"]["base_64"] !== null &&
          sample["image_one"]["id"] === null
        ) {
          response = await createImage({
            ...sample["image_one"],
            sample_id: sample["id"],
          });
          sample["image_one"]["id"] = response["id"];
        }
        if (
          sample["image_two"]["base_64"] !== null &&
          sample["image_two"]["id"] === null
        ) {
          response = await createImage({
            ...sample["image_two"],
            sample_id: sample["id"],
          });
          sample["image_two"]["id"] = response["id"];
        }
        delete sample["is_update"];
        let tests = [];
        for (let test of sample["tests"]) {
          if (test["id"] !== null && test["test_template_id"] === "") {
            await deleteTest(test);
          }
          if (
            test["id"] !== null &&
            test["sample_id"] !== null &&
            test["test_template_id"] !== ""
          ) {
            await updateTest(test);
            tests.push(test);
          } else if (
            test["sample_id"] !== null &&
            test["test_template_id"] !== ""
          ) {
            response = await createTest(test);
            test["id"] = response["id"];
            tests.push(test);
          }
        }
        models["samples"].splice(
          models["samples"].findIndex((model) => model.id === sample.id),
          1,
          sample
        );
        break;
      case "test_templates":
        const test_template = { ...this.state.selected_model };
        await updateTestTemplate(test_template);
        delete test_template["is_update"];
        models["test_templates"].splice(
          models["test_templates"].findIndex(
            (model) => model.id === test_template.id
          ),
          1,
          test_template
        );
        break;
      case "groups":
        const group = { ...this.state.selected_model };
        await updateGroup(group);
        delete group["is_update"];
        models["groups"].splice(
          models["groups"].findIndex((model) => model.id === group.id),
          1,
          group
        );
        break;
      case "matrices":
        const matrix = { ...this.state.selected_model };
        await updateMatrix(matrix);
        delete matrix["is_update"];
        models["matrices"].splice(
          models["matrices"].findIndex((model) => model.id === matrix.id),
          1,
          matrix
        );
        break;
      case "rules":
        const rule = { ...this.state.selected_model };
        await updateRule(rule);
        delete rule["is_update"];
        models["rules"].splice(
          models["rules"].findIndex((model) => model.id === rule.id),
          1,
          rule
        );
        break;
      default:
        console.log("Default case");
    }
    this.setStateForKey("models", models);
    this.resetSelectedModel();
    this.setStateForKey("is_loading", false);
  };

  deleteModel = async () => {
    this.setStateForKey("showDeleteModal", true);
  };

  handleDeleteCodeSubmit = () => {
    if (this.state.deleteCode === "rvzNW9MJ%%") {
      this.proceedWithDeletion();
    } else {
      alert("Invalid code. Please try again.");
    }
    this.setStateForKey("showDeleteModal", false);
    this.setStateForKey("deleteCode", "");
  };

  proceedWithDeletion = async () => {
    this.setStateForKey("is_loading", true);
    const models = { ...this.state.models };
    switch (this.state.selected_tab) {
      case "addresses":
        const address = { ...this.state.selected_model };
        await deleteAddress(address);
        models["addresses"].splice(
          models["addresses"].findIndex((model) => model.id === address.id),
          1
        );
        break;
      case "contacts":
        const contact = { ...this.state.selected_model };
        await deleteContact(contact);
        models["contacts"].splice(
          models["contacts"].findIndex((model) => model.id === contact.id),
          1
        );
        break;
      case "billing_accounts":
        const billing_account = { ...this.state.selected_model };
        await deleteBillingAccount(billing_account);
        models["billing_accounts"].splice(
          models["billing_accounts"].findIndex(
            (model) => model.id === billing_account.id
          ),
          1
        );
        break;
      case "customers":
        const customer = { ...this.state.selected_model };
        await deleteCustomer(customer);
        models["customers"].splice(
          models["customers"].findIndex((model) => model.id === customer.id),
          1
        );
        break;
      case "work_orders":
        const work_order = { ...this.state.selected_model };
        await deleteWorkOrder(work_order);
        models["work_orders"].splice(
          models["work_orders"].findIndex(
            (model) => model.id === work_order.id
          ),
          1
        );
        break;
      case "samples":
        const sample = { ...this.state.selected_model };
        await deleteSample(sample);
        if (sample["image_one"]["id"] !== null) {
          deleteImage(sample["image_one"]);
        }
        if (sample["image_two"]["id"] !== null) {
          deleteImage(sample["image_two"]);
        }
        for (let test of sample["tests"]) {
          if (test.id !== null) {
            await deleteTest(test);
          }
        }
        models["samples"].splice(
          models["samples"].findIndex((model) => model.id === sample.id),
          1
        );
        break;
      case "test_templates":
        const test_template = { ...this.state.selected_model };
        await deleteTestTemplate(test_template);
        models["test_templates"].splice(
          models["test_templates"].findIndex(
            (model) => model.id === test_template.id
          ),
          1
        );
        break;
      case "groups":
        const group = { ...this.state.selected_model };
        await deleteGroup(group);
        models["groups"].splice(
          models["groups"].findIndex((model) => model.id === group.id),
          1
        );
        break;
      case "matrices":
        const matrix = { ...this.state.selected_model };
        await deleteMatrix(matrix);
        models["matrices"].splice(
          models["matrices"].findIndex((model) => model.id === matrix.id),
          1
        );
        break;
      case "rules":
        const rule = { ...this.state.selected_model };
        await deleteRule(rule);
        models["rules"].splice(
          models["rules"].findIndex((model) => model.id === rule.id),
          1
        );
        break;
      default:
        console.log("Default case");
    }
    this.setStateForKey("models", models);
    this.resetSelectedModel();
    this.setStateForKey("is_loading", false);
  };

  generateReport = async () => {
    await this.updateModel();
    const sample = { ...this.state.selected_model };
    window.open(`/samples/${encrypt(sample.id)}/report`, "_blank")?.focus();
  };

  renderTable = (model, models, tab, is_loading) => {
    switch (model) {
      case "Billing Accounts":
        return (
          <BillingAccountsTable
            setSelectedModel={this.setSelectedModel}
            state={{ models, tab, is_loading }}
          />
        );
      case "Customers":
        return (
          <CustomersTable
            setSelectedModel={this.setSelectedModel}
            state={{ models, tab, is_loading }}
          />
        );
      case "Work Orders":
        return (
          <WorkOrdersTable
            setSelectedModel={this.setSelectedModel}
            state={{ models, tab, is_loading }}
          />
        );
      case "Samples":
        return (
          <SamplesTable
            setSelectedModel={this.setSelectedModel}
            state={{ models, tab, is_loading }}
          />
        );
      case "Test Templates":
        return (
          <TestTemplatesTable
            setSelectedModel={this.setSelectedModel}
            state={{ models, tab, is_loading }}
          />
        );
      case "Matrices":
        return (
          <MatricesTable
            setSelectedModel={this.setSelectedModel}
            state={{ models, tab, is_loading }}
          />
        );
      case "Rules":
        return (
          <RulesTable
            setSelectedModel={this.setSelectedModel}
            state={{ models, tab, is_loading }}
          />
        );
      case "Contacts":
        return (
          <ContactsTable
            setSelectedModel={this.setSelectedModel}
            state={{ models, tab, is_loading }}
          />
        );
      default:
        return (
          <BaseTable
            setSelectedModel={this.setSelectedModel}
            state={{ models, tab, is_loading }}
          />
        );
    }
  };

  renderForm = (model, models, selected_model, is_loading) => {
    switch (model) {
      case "Billing Accounts":
        return (
          <BillingAccountsForm
            createModel={this.createModel}
            updateModel={this.updateModel}
            deleteModel={this.deleteModel}
            handleChange={this.handleChange}
            resetSelectedModel={this.resetSelectedModel}
            state={{ models, selected_model, is_loading }}
          />
        );
      case "Customers":
        return (
          <CustomersForm
            createModel={this.createModel}
            updateModel={this.updateModel}
            deleteModel={this.deleteModel}
            handleChange={this.handleChange}
            resetSelectedModel={this.resetSelectedModel}
            state={{ models, selected_model, is_loading }}
          />
        );
      case "Work Orders":
        return (
          <WorkOrdersForm
            createModel={this.createModel}
            updateModel={this.updateModel}
            deleteModel={this.deleteModel}
            handleChange={this.handleChange}
            resetSelectedModel={this.resetSelectedModel}
            state={{ models, selected_model, is_loading }}
          />
        );
      case "Samples":
        return (
          <SamplesForm
            createModel={this.createModel}
            updateModel={this.updateModel}
            deleteModel={this.deleteModel}
            handleChange={this.handleChange}
            resetSelectedModel={this.resetSelectedModel}
            uploadImage={this.uploadImage}
            deleteImage={this.deleteImage}
            addTestToSample={this.addTestToSample}
            generateReport={this.generateReport}
            state={{ models, selected_model, is_loading }}
          />
        );
      case "Test Templates":
        return (
          <TestTemplatesForm
            createModel={this.createModel}
            updateModel={this.updateModel}
            deleteModel={this.deleteModel}
            handleChange={this.handleChange}
            resetSelectedModel={this.resetSelectedModel}
            state={{ models, selected_model, is_loading }}
          />
        );
      case "Matrices":
        return (
          <MatricesForm
            createModel={this.createModel}
            updateModel={this.updateModel}
            deleteModel={this.deleteModel}
            handleChange={this.handleChange}
            resetSelectedModel={this.resetSelectedModel}
            state={{ models, selected_model, is_loading }}
          />
        );
      case "Rules":
        return (
          <RulesForm
            createModel={this.createModel}
            updateModel={this.updateModel}
            deleteModel={this.deleteModel}
            handleChange={this.handleChange}
            resetSelectedModel={this.resetSelectedModel}
            state={{ models, selected_model, is_loading }}
          />
        );
      case "Contacts":
        return (
          <ContactsForm
            createModel={this.createModel}
            updateModel={this.updateModel}
            deleteModel={this.deleteModel}
            handleChange={this.handleChange}
            resetSelectedModel={this.resetSelectedModel}
            state={{ models, selected_model, is_loading }}
          />
        );
      default:
        return (
          <BaseForm
            createModel={this.createModel}
            updateModel={this.updateModel}
            deleteModel={this.deleteModel}
            handleChange={this.handleChange}
            resetSelectedModel={this.resetSelectedModel}
            state={{ models, selected_model, is_loading }}
          />
        );
    }
  };

  render() {
    const { models, selected_model, auth, code, is_loading } = this.state;
    return (
      <div>
        <Navbar
          bg="light"
          variant="light"
          expand="lg"
          style={{
            borderBottom: "1px solid rgba(0,0,0,.1)",
            padding: "10px 15px",
          }}
        >
          <Container fluid>
            <Navbar.Brand>Cora Science Admin</Navbar.Brand>
            <Navbar.Collapse className="justify-content-end">
              <GenerateInvoice state={{ models }} />
              <Navbar.Text>
                {auth ? "Signed in as: Admin" : "Signed Out"}
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div
          style={{ padding: "30px 15px", background: "rgba(203,203,210,.15)" }}
        >
          {auth ? (
            <Container fluid>
              {this.state.selected_tab && (
                <Tabs
                  activeKey={this.state.selected_tab}
                  onSelect={(tab) => this.setSelectedTab(tab)}
                  className="mb-3"
                >
                  {Object.keys(models).map((tab, index) => (
                    <Tab
                      key={index}
                      eventKey={tab}
                      title={upperCaseWords(tab.split("_"))}
                    >
                      <Row style={{ display: "flex", flexWrap: "wrap" }}>
                        <Col xs={12} md={12}>
                          <Button
                            variant="primary"
                            disabled={is_loading}
                            style={{
                              marginBottom: "1rem",
                              marginRight: "1rem",
                            }}
                            onClick={(e) =>
                              !is_loading
                                ? this.setSelectedModel(
                                    e,
                                    tab,
                                    models[tab].length + 1
                                  )
                                : null
                            }
                          >
                            {is_loading ? "Loading…" : "Add New"}
                          </Button>
                          {tab == "samples" && (
                            <BulkTestUpload state={{ models }} />
                          )}
                          {models[tab].length ? (
                            this.renderTable(
                              upperCaseWords(tab.split("_")),
                              models,
                              tab,
                              is_loading
                            )
                          ) : (
                            <p>
                              You should probably add some data to this table...
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Tab>
                  ))}
                </Tabs>
              )}
              {selected_model &&
                this.renderForm(
                  upperCaseWords(this.state.selected_tab.split("_")),
                  models,
                  selected_model,
                  is_loading
                )}
                <Modal
                  show={this.state.showDeleteModal}
                  onHide={() => this.setStateForKey("showDeleteModal", false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Please enter the code to confirm deletion:</p>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        value={this.state.deleteCode}
                        onChange={(e) => this.setStateForKey("deleteCode", e.target.value)}
                      />
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => this.setStateForKey("showDeleteModal", false)}
                    >
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={this.handleDeleteCodeSubmit}>
                      Confirm
                    </Button>
                  </Modal.Footer>
                </Modal>
            </Container>
          ) : (
            <Container
              fluid
              style={{
                height: "calc(100vh - 123px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {this.state.is_loading ? (
                <Row style={{ width: "50%" }}>
                  <Col xs={12} md={12}>
                    <p>Signing in...</p>
                  </Col>
                </Row>
              ) : (
                <Row style={{ width: "50%" }}>
                  <Col xs={12} md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="Code"
                        style={{
                          fontSize: "12px",
                          marginBottom: "5px",
                          textTransform: "uppercase",
                          color: "#9a9a9a",
                        }}
                      >
                        Code
                      </Form.Label>
                      <Form.Control
                        id="Code"
                        value={code}
                        onChange={(e) => this.handleCode(e)}
                      />
                    </Form.Group>
                    <Button
                      variant="primary"
                      onClick={(e) => this.submitCode(e)}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              )}
            </Container>
          )}
        </div>
      </div>
    );
  }
}

export default Home;
