import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useLoading } from '../contexts/LoadingContext';

const GlobalSpinner = () => {
  const { loading } = useLoading();

  return (
    loading && (
      <div className="d-flex justify-content-center align-items-center" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 9999, background: 'rgba(0, 0, 0, 0.5)' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    )
  );
};

export default GlobalSpinner;
