import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import QRCode from 'qrcode.react';
import { Table, Button, Modal, Form, Dropdown, Navbar, Container, Row, Col, Alert } from 'react-bootstrap';
import {  fetchTestTemplates, fetchCode, fetchGroups, fetchMatrices, createSubmission, fetchWorkOrdersByUuid, fetchSamplesByUuid } from '../api';
import { useLoading } from '../contexts/LoadingContext';
import { useParams } from "react-router-dom";
import { timestampToDate, encrypt } from '../utils';

const Submission = () => {
  const [pastWorkOrders, setPastWorkOrders] = useState([]);  // Dummy work order data
  const [pastSamples, setPastSamples] = useState([]);
  const [samples, setSamples] = useState([]);
  const [tests, setTests] = useState([]);
  const [groups, setGroups] = useState([]);
  const [matrices, setMatrices] = useState([]);
  const [code, setCode] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editingSample, setEditingSample] = useState(null);
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [formData, setFormData] = useState({
    sampleName: '',
    groupType: 'FOOD',
    matrixType: 'BEVERAGE',
    lotOrBatch: '',
    quantityProvided: 0,
    unit: 'ml',
    sampleStorageConditions: 'AMBIENT',
    testsRequested: [],
    turnaroundTime: 'STANDARD',
    notes: '',
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [sendDate, setSendDate] = useState(new Date().toISOString().substr(0, 10));
  const { setLoading } = useLoading();
  const uuid = useParams()['id'];

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const test_templates = await fetchTestTemplates();
        setTests(test_templates.filter(test_template => test_template.published));
        const groups = await fetchGroups();
        setGroups(groups);
        const matrices = await fetchMatrices();
        setMatrices(matrices);
        const code = await fetchCode(uuid);
        setFormData({ ...formData, 'groupType': groups[0].name.toUpperCase(), 'matrixType': matrices.filter(matrix => groups.find(group => group.id === matrix.group_id && group.name === groups[0].name))[0].name.toUpperCase() });
        setCode(code);
        const fetchedPastWorkOrders = await fetchWorkOrdersByUuid(uuid);
        setPastWorkOrders(fetchedPastWorkOrders);
        const fetchedPastSamples = await fetchSamplesByUuid(uuid);
        setPastSamples(fetchedPastSamples);
      } catch (error) {
        console.error('Error fetching code:', error);
      }
      setLoading(false);
    }
    fetchData();
  }, []);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === 'groupType') {
      setFormData({ ...formData, [name]: value, 'matrixType': matrices.filter(matrix => groups.find(group => group.id === matrix.group_id && group.name.toUpperCase() === value.toUpperCase()))[0].name.toUpperCase() });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSelect = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleTestsSelect = (selectedTests) => {
    setFormData({ ...formData, testsRequested: selectedTests });
  };

  const isValid = () => {
    return (
      formData.sampleName &&
      formData.lotOrBatch &&
      formData.quantityProvided &&
      formData.testsRequested.length > 0
    );
  };

  const handleAddSample = () => {
    if (!isValid()) {
      return;
    }
    if (editingSample !== null) {
      const updatedSamples = samples.map((sample, index) =>
        index === editingSample ? formData : sample
      );
      setSamples(updatedSamples);
      setEditingSample(null);
    } else {
      setSamples([...samples, formData]);
    }
    setFormData({
      sampleName: '',
      groupType: groups[0].name.toUpperCase(), 
      matrixType: matrices.filter(matrix => groups.find(group => group.id === matrix.group_id && group.name === groups[0].name))[0].name.toUpperCase(),
      lotOrBatch: '',
      quantityProvided: 0,
      unit: 'ml',
      sampleStorageConditions: 'AMBIENT',
      testsRequested: [],
      turnaroundTime: 'STANDARD',
      notes: '',
    });
    setShowModal(false);
  };

  const handleEditSample = (index) => {
    setEditingSample(index);
    setFormData(samples[index]);
    setShowModal(true);
  };

  const handleDeleteSample = (index) => {
    const updatedSamples = samples.filter((_, i) => i !== index);
    setSamples(updatedSamples);
  };

  const handleCopySample = (index) => {
    setSamples([...samples, samples[index]]);
  };

  const submitSamples = () => {
    setShowConfirmModal(true);
  };

  const handleSubmitAndPrint = async () => {
    setShowConfirmModal(false);
    setLoading(true);
    try {
      const response = await createSubmission(code['uuid'], samples, {'sendDate': sendDate});
  
      if (response.id) {
        const checkinUrl = `https://app.corascience.com/checkin/${response.id}`;
        setQrCodeUrl(checkinUrl);
        setLoading(false);
        setTimeout(() => {
          window.print();
        }, 500);
      }
    } catch (error) {
      console.error('Error submitting samples:', error);
    }
    setLoading(false);
  };

  return (
    <div>
      <Navbar bg="light" variant="light" expand="lg" style={{'borderBottom':'1px solid rgba(0,0,0,.1)','padding':'10px 15px'}}>
        <Container fluid>
          <Navbar.Brand>
            Cora Science Client Portal
          </Navbar.Brand>
        </Container>
      </Navbar>
      <div style={{'padding': '30px 15px', 'background': 'rgba(203,203,210,.15)'}}>
        {code && code.active ? 
        <Container fluid style={{ 'height': 'calc(100vh - 123px)'}}>
          <>
            <h3 style={{ marginTop: '20px' }}>Sample Submission</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Sample Name</th>
                  <th>Group Type</th>
                  <th>Matrix Type</th>
                  <th>Lot or Batch</th>
                  <th>Quantity Provided</th>
                  <th>Sample Storage Conditions</th>
                  <th>Tests Requested</th>
                  <th>Turnaround Time</th>
                  <th>Notes</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {samples.map((sample, index) => (
                  <tr key={index}>
                    <td>{sample.sampleName}</td>
                    <td>{sample.groupType}</td>
                    <td>{sample.matrixType}</td>
                    <td>{sample.lotOrBatch}</td>
                    <td>
                      {sample.quantityProvided} {sample.unit}
                    </td>
                    <td>{sample.sampleStorageConditions}</td>
                    <td>{sample.testsRequested.map((test) => test.label).join(', ')}</td>
                    <td>{sample.turnaroundTime}</td>
                    <td>{sample.notes}</td>
                    <td style={{'display': 'flex', 'justifyContent': 'space-evenly'}}>
                      <Button disabled={qrCodeUrl} variant="primary" onClick={() => handleEditSample(index)}>
                        Edit
                      </Button>
                      <Button disabled={qrCodeUrl} variant="danger" onClick={() => handleDeleteSample(index)}>
                        Delete
                      </Button>
                      <Button disabled={qrCodeUrl} variant="success" onClick={() => handleCopySample(index)}>
                        Copy
                      </Button>
                    </td>
                  </tr>
                ))}
                </tbody>
              </Table>
              {
                qrCodeUrl ?
                <div className="qr-code-container">
                  <QRCode value={qrCodeUrl} size={window.innerWidth * 0.25} />
                </div>
                :
                <>
                  <Button onClick={() => setShowModal(true)}>Add Sample</Button>
                  {samples.length > 0 && (
                    <Button onClick={submitSamples} className="ml-2" style={{'marginLeft': '10px'}}>
                      Submit Samples
                    </Button>
                  )}
                  { code.admin && 
                  <>
                  <h3 style={{ marginTop: '20px' }}>Work Order History</h3>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Work Order</th>
                        <th>Date Generated</th>
                        <th>Lot Number</th>
                        <th>Sample Name</th>
                        <th>PDF Report</th>
                      </tr>
                    </thead>
                    <tbody>
                    {pastWorkOrders.map((order) => {
                      const relatedSamples = pastSamples.filter(sample => sample.work_order_id === order.id);
                      return (
                        <React.Fragment key={order.internal_id}>
                          {relatedSamples.length > 0 && (
                            relatedSamples.map((sample) => (
                              <tr key={sample.id}>
                                <td>{order.internal_id}</td>
                                <td>{timestampToDate(order.received_at)}</td>
                                <td>{sample.metadata.customer_lot_number}</td>
                                <td>{sample.metadata.name}</td>
                                <td>
                                  <Button onClick={() => window.open(`/samples/${encrypt(sample.id)}/report`, "_blank")?.focus()}>
                                    View PDF
                                  </Button>
                                </td>
                              </tr>
                            ))
                          )}
                        </React.Fragment>
                      );
                    })}
                    </tbody>
                  </Table>
                  </>}
                </>
              }
              
              <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>{ editingSample !== null ? 'Edit Sample' : 'Add Sample' }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>

                    <Form.Group controlId="sampleName" style={{ 'marginBottom' : '15px' }}>
                      <Form.Label>Sample Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="sampleName"
                        value={formData.sampleName}
                        onChange={handleFormChange}
                        isInvalid={!formData.sampleName}
                      />
                      <Form.Control.Feedback type="invalid">
                        Sample Name is required.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="groupType" style={{ 'marginBottom' : '15px' }}>
                      <Form.Label>Group Type</Form.Label>
                      <Form.Control
                        as="select"
                        name="groupType"
                        value={formData.groupType}
                        onChange={handleFormChange}
                      >
                      {
                        Object.values(groups).map((value, index) => (
                          <option key={index} value={value.name.toUpperCase()}>{value.name.toUpperCase()}</option>
                        ))
                      }
                      </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="matrixType" style={{ 'marginBottom' : '15px' }}>
                      <Form.Label>Matrix Type</Form.Label>
                      <Form.Control
                        as="select"
                        name="matrixType"
                        value={formData.matrixType}
                        onChange={handleFormChange}
                      >
                        {
                          Object.values(matrices.filter(matrix => groups.find(group => group.id === matrix.group_id && group.name.toUpperCase() === formData.groupType.toUpperCase()))).map((value, index) => (
                            <option key={index} value={value.name.toUpperCase()}>{value.name.toUpperCase()}</option>
                          ))
                        }
                      </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="lotOrBatch" style={{ 'marginBottom' : '15px' }}>
                      <Form.Label>Lot or Batch</Form.Label>
                      <Form.Control
                        type="text"
                        name="lotOrBatch"
                        value={formData.lotOrBatch}
                        onChange={handleFormChange}
                        isInvalid={!formData.lotOrBatch}
                      />
                      <Form.Control.Feedback type="invalid">
                        Lot / Batch is required.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="quantityProvided" style={{ 'marginBottom' : '15px' }}>
                      <Form.Label>Quantity Provided</Form.Label>
                      <div className="d-flex">
                        <Form.Control
                          type="number"
                          name="quantityProvided"
                          value={formData.quantityProvided}
                          onChange={handleFormChange}
                          className="mr-2"
                          isInvalid={!formData.quantityProvided}
                        />
                        <Form.Control.Feedback type="invalid">
                          Quantity Provided is required.
                        </Form.Control.Feedback>
                        <Dropdown onSelect={(value) => handleSelect('unit', value)}>
                          <Dropdown.Toggle variant="success">
                            {formData.unit || 'ml'}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item eventKey="ml">ml</Dropdown.Item>
                            <Dropdown.Item eventKey="g">g</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Form.Group>

                    <Form.Group controlId="sampleStorageConditions" style={{ 'marginBottom' : '15px' }}>
                      <Form.Label>Sample Storage Conditions</Form.Label>
                      <Form.Control
                        as="select"
                        name="sampleStorageConditions"
                        value={formData.sampleStorageConditions}
                        onChange={handleFormChange}
                      >
                        <option key='AMBIENT' value='AMBIENT'>AMBIENT</option>
                        <option key='REFRIGERATED' value='REFRIGERATED'>REFRIGERATED</option>
                        <option key='FROZEN' value='FROZEN'>FROZEN</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="testsRequested" style={{ 'marginBottom' : '15px' }}>
                      <Form.Label>Tests Requested</Form.Label>
                      <Select
                        isMulti
                        name="testsRequested"
                        options={tests.map((test) => ({ value: test.internal_id, label: test.internal_id + " " + test.assay }))}
                        onChange={handleTestsSelect}
                        value={formData.testsRequested.map((test) => ({
                          value: test.value,
                          label: test.label,
                        }))}
                        className={formData.testsRequested.length > 0 ? '' : 'is-invalid'}
                      />
                      {formData.testsRequested.length === 0 && (
                        <div className="invalid-feedback d-block">
                          Please select at least one test.
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group controlId="turnaroundTime" style={{ 'marginBottom' : '15px' }}>
                      <Form.Label>Turnaround Time</Form.Label>
                      <Form.Control
                        as="select"
                        name="turnaroundTime"
                        value={formData.turnaroundTime}
                        onChange={handleFormChange}
                      >
                        <option key='STANDARD' value='STANDARD'>STANDARD</option>
                        <option key='RUSH-24HR' value='RUSH-24HR'>RUSH-24HR</option>
                        <option key='RUSH-48HR' value='RUSH-48HR'>RUSH-48HR</option>
                        <option key='RUSH-72HR' value='RUSH-72HR'>RUSH-72HR</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="notes" style={{ 'marginBottom' : '15px' }}>
                      <Form.Label>Notes</Form.Label>
                      <Form.Control
                      as="textarea"
                      name="notes"
                      value={formData.notes}
                      onChange={handleFormChange}
                      rows={4}
                      />
                    </Form.Group>

                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Close
                  </Button>
                  <Button disabled={!isValid()} variant="primary" onClick={handleAddSample}>
                    {editingSample !== null ? 'Update Sample' : 'Add Sample'}
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Sample Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Please confirm that the information you've submitted is accurate.</p>
                  <Form.Group controlId="sendDate">
                    <Form.Label>Planned Send Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="sendDate"
                      value={sendDate}
                      onChange={(e) => setSendDate(e.target.value)}
                    />
                  </Form.Group>
                  <p style={{'marginTop': '15px'}}>
                    <span style={{'fontSize': '15px'}}>Make sure to <b style={{'fontSize': '25px'}}>print this page</b> with the samples table and <b style={{'fontSize': '25px'}}>send it in with your order.</b></span>
                  </p>
                  
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={handleSubmitAndPrint}>
                    Submit and Print
                  </Button>
                </Modal.Footer>
              </Modal>
          </>
        </Container>
        :
        <Container fluid style={{ 'height': 'calc(100vh - 123px)', 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center'}}>
          <Row className="justify-content-md-center" style={{ 'width': '50%' }}>
            <Col  xs={12} md={12}>
              <Alert variant="info">
                Link has already been used or is invalid. Please go <a href="/testing">here</a> to request a new secure link.
              </Alert>
            </Col>
          </Row>
        </Container>
        }
      </div>
    </div>
  );
};

export default Submission;