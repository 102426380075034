import React from 'react';
import Table from 'react-bootstrap/Table';
import { upperCaseWords } from '../../utils';

const BaseTable = (props) => {
  return (
    <Table striped bordered hover variant="light">
      <thead>
        <tr>
          {
            Object.keys(props.state.models[props.state.tab][0]).map((key, index) => (
              ['id', 'created_at', 'updated_at', 'is_update'].includes(key) ? null :
              <th key={index}>{upperCaseWords(key.split('_'))}</th>
            ))
          }
        </tr>
      </thead>
      <tbody>
        {
          props.state.models[props.state.tab].map((model, index) => (
            <tr key={index} style={{ 'cursor': 'pointer' }} onClick={e => !props.state.is_loading ? props.setSelectedModel(e, props.state.tab, index) : null}>
            {
              Object.keys(model).map((key, index) => (
                ['id', 'created_at', 'updated_at', 'is_update'].includes(key) ? null :
                <td key={index}>{model[key]}</td>
              ))
            }
            </tr>
          ))
        }
      </tbody>
    </Table>
  )
}
export default BaseTable;