import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

function renderWorkOrder(id, work_orders) {
  const work_order = work_orders.find(work_order => work_order.id === id);
  return work_order.internal_id;
}

function cleanModels(models, work_orders) {
  return models.map(model => {
    return {
      id: model.id,
      internal_id: model.internal_id,
      work_order_id: renderWorkOrder(model.work_order_id, work_orders),
      name: model.metadata.name,
      customer_lot_number: model.metadata.customer_lot_number,
      group: model.metadata.group,
      matrix: model.metadata.matrix,
      status: model.metadata.status,
      turnaround_time: model.metadata.turnaround_time,
      tests_requested: 'test_internal_ids' in model ? model.test_internal_ids.join(", ") : [],
      for_release: model.metadata.for_release !== undefined ? model.metadata.for_release : false
    }
  });
}

const columns = [{
  dataField: 'internal_id',
  text: 'ID',
  sort: true,
  sortFunc: (a, b, order, dataField, rowA, rowB) => {
    a = a.replace(/\D/g,'');
    b = b.replace(/\D/g,'');
    if (order === 'asc') {
      return a - b;
    }
    return b - a;
  }
}, {
  dataField: 'work_order_id',
  text: 'Work Order ID',
  sort: true
}, {
  dataField: 'turnaround_time',
  text: 'Turnaround Time',
  sort: true
}, {
  dataField: 'tests_requested',
  text: 'Tests Requested'
}, {
  dataField: 'status',
  text: 'Status',
  sort: true
}, {
  dataField: 'name',
  text: 'Name',
  sort: true
}, {
  dataField: 'customer_lot_number',
  text: 'Lot',
  sort: true
}, {
  dataField: 'group',
  text: 'Group',
  sort: true
}, {
  dataField: 'matrix',
  text: 'Matrix',
  sort: true
}, {
  dataField: 'for_release',
  text: 'For Release',
  formatter: (cell) => cell ? '✓' : 'x',
  sort: true
}];

const rowClass = (row, rowIndex) => {
  if (row.turnaround_time === "RUSH-24HR" && row.status !== "REPORT RELEASED") {
    return 'light-red';
  }
  if (row.turnaround_time === "RUSH-48HR" && row.status !== "REPORT RELEASED") {
    return 'light-orange';
  }
  if (row.turnaround_time === "RUSH-72HR" && row.status !== "REPORT RELEASED") {
    return 'light-blue';
  }
  if (row.status === "REPORT RELEASED") {
    return 'light-grey';
  }
  return '';
};

const SamplesTable = (props) => {
  const samples = cleanModels(props.state.models[props.state.tab], props.state.models['work_orders']);

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (!props.state.is_loading) {
        props.setSelectedModel(e, props.state.tab, rowIndex, row.id);
      }
    }
  };
  
  return (
    <BootstrapTable keyField='internal_id' data={ samples } columns={ columns } rowEvents={ rowEvents } rowClasses={ rowClass } bootstrap4 />
  )
}

export default SamplesTable;