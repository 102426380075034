import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { upperCaseWords, timestampToDate } from '../../utils';

function cleanModel(model) {
    return {
        internal_id: model.internal_id,
        customer_id: model.customer_id,
        invoice_status: model.invoice_status,
        job_status: model.job_status,
        received_at: timestampToDate(model.received_at),
        payment_method: model.payment_method,
        is_published: model.is_published,
        notes: model.notes,
        is_update: model.is_update
    }
}

const WorkOrdersForm = (props) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [confirmPublish, setConfirmPublish] = useState(false);

    const selected_model = cleanModel(props.state.selected_model);
    const selected_customer = props.state.models['customers'].find(customer => customer.id === selected_model.customer_id);
    const default_payment_method = selected_customer ? selected_customer.payment_method : 'Stripe Direct Debit';

    const isPublishEnabled = !['RECEIVED', 'IN PROGRESS'].includes(selected_model.job_status);

    const handleSaveClick = () => {
        // NOTE: Remove confirm modal when publishing
        // if (isPublishEnabled) {
        //     if (!selected_model.is_published) {
        //         setConfirmPublish(false);
        //         setShowConfirmModal(true);
        //     } else {
        //         setConfirmPublish(true);
        //         setShowConfirmModal(true);
        //     }
        // } else {
        //     selected_model.is_update ? props.updateModel() : props.createModel();
        // }
        selected_model.is_update ? props.updateModel() : props.createModel();
    }

    const handleConfirm = () => {
        setShowConfirmModal(false);
        if (confirmPublish) {
            props.handleChange({
                target: {
                    name: 'is_published',
                    value: true,
                    checked: true,
                    type: 'checkbox'
                }
            });
        } else {
            props.handleChange({
                target: {
                    name: 'is_published',
                    value: false,
                    checked: false,
                    type: 'checkbox'
                }
            });
        }
        selected_model.is_update ? props.updateModel() : props.createModel();
    }

    return (
        <>
        <Modal show={selected_model !== null} onHide={props.resetSelectedModel}>
            <Modal.Header closeButton>
                <Modal.Title>{ selected_model.is_update ? 'Edit' : 'Add' }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                Object.keys(selected_model).map((key, index) => (
                    ['is_update'].includes(key) ? null :
                    <Row key={index}>
                    <Col xs={12} md={12}>
                        <Form.Group className="mb-3">
                        <Form.Label htmlFor={'input' + key} style={{'fontSize': '12px', 'marginBottom': '5px', 'textTransform': 'uppercase', 'color': '#9a9a9a'}}>{upperCaseWords((key.split('_').length > 1 && key.split('_')[0] !== 'internal' && key.split('_')[1] === 'id') ? [key.split('_')[0]] : key.split('_'))}</Form.Label>
                        {
                            "customer_id" === key ?
                            <Form.Select id={'input' + key} name={key} value={selected_model[key]} onChange={e => props.handleChange(e)}>
                            <option key='-1' value=''></option>
                            {
                                Object.values(props.state.models['customers']).map((value, index) => (
                                    <option key={index} value={value.id}>{value.name}</option>
                                ))
                            }
                            </Form.Select>
                            :
                            "invoice_status" === key ?
                            <Form.Select id={'input' + key} name={key} value={selected_model[key]} onChange={e => props.handleChange(e)}>
                                <option key='GENERATED' value='GENERATED'>GENERATED</option>
                                <option key='INVOICED' value='INVOICED'>INVOICED</option>
                                <option key='PAID' value='PAID'>PAID</option>
                            </Form.Select>
                            :
                            "job_status" === key ?
                            <Form.Select id={'input' + key} name={key} value={selected_model[key]} onChange={e => props.handleChange(e)}>
                                <option key='RECEIVED' value='RECEIVED'>RECEIVED</option>
                                <option key='IN PROGRESS' value='IN PROGRESS'>IN PROGRESS</option>
                                <option key='PARTIALLY COMPLETE' value='PARTIALLY COMPLETE'>PARTIALLY COMPLETE</option>
                                <option key='COMPLETE' value='COMPLETE'>COMPLETE</option>
                            </Form.Select>
                            :
                            "payment_method" === key ?
                            <Form.Select id={'input' + key} name={key} value={selected_model[key] || default_payment_method} onChange={e => props.handleChange(e)}>
                                <option key='Stripe Direct Debit' value='Stripe Direct Debit'>Stripe Direct Debit</option>
                                <option key='Stripe Credit' value='Stripe Credit'>Stripe Credit</option>
                                <option key='ACH' value='ACH'>ACH</option>
                                <option key='Check' value='Check'>Check</option>
                            </Form.Select>
                            :
                            "received_at" === key ?
                            <Form.Control type="date" id={'input' + key} name={key} value={timestampToDate(selected_model[key])} onChange={e => props.handleChange(e)} />
                            :
                            "notes" === key ?
                            <Form.Control as="textarea" rows={3} id={'input' + key} name={key} value={selected_model[key]} onChange={e => props.handleChange(e)} />
                            :
                            "is_published" === key ?
                            <Form.Check type="checkbox" id={'input' + key} name={key} label="Publish to Client" disabled={!isPublishEnabled} checked={selected_model[key]} onChange={e => props.handleChange({ target: { name: key, value: e.target.checked } })}/>
                            :
                            <Form.Control id={'input' + key} name={key} value={selected_model[key]} onChange={e => props.handleChange(e)} />
                        }
                        </Form.Group>
                    </Col>
                    </Row>
                ))
                }
            </Modal.Body>
            <Modal.Footer>
                {selected_model.is_update &&
                    <Button variant="danger" disabled={props.state.is_loading} onClick={props.deleteModel}>Delete</Button>}
                <Button variant="primary" disabled={props.state.is_loading} onClick={handleSaveClick}>
                    {selected_model.is_update ? 'Save' : 'Create'}
                </Button>
            </Modal.Footer>
            </Modal>
            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{confirmPublish ? 'Publish Work Order' : 'Not Publish Work Order'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Are you sure you {confirmPublish ? 'want to publish' : "don't want to publish"} this work order?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>Cancel</Button>
                    <Button variant="primary" onClick={handleConfirm}>
                        {confirmPublish ? 'Yes, Publish' : 'Yes, Do Not Publish'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
  )
}
export default WorkOrdersForm;


