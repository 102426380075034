import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { upperCaseWords, timestampToDate } from '../../utils';

function cleanModel(model) {
    return {
        group_id: model.group_id,
        name: model.name,
        is_update: model.is_update
    }
}

const MatricesForm = (props) => {
    const selected_model = cleanModel(props.state.selected_model);

    return (
    <Modal show={selected_model !== null} onHide={props.resetSelectedModel}>
        <Modal.Header closeButton>
            <Modal.Title>{ selected_model.is_update ? 'Edit' : 'Add' }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
            Object.keys(selected_model).map((key, index) => (
                ['is_update'].includes(key) ? null :
                <Row key={index}>
                <Col xs={12} md={12}>
                    <Form.Group className="mb-3">
                    <Form.Label htmlFor={'input' + key} style={{'fontSize': '12px', 'marginBottom': '5px', 'textTransform': 'uppercase', 'color': '#9a9a9a'}}>{upperCaseWords((key.split('_').length > 1 && key.split('_')[0] !== 'internal' && key.split('_')[1] === 'id') ? [key.split('_')[0]] : key.split('_'))}</Form.Label>
                    {
                        "group_id" === key ?
                        <Form.Select id={'input' + key} name={key} value={selected_model[key]} onChange={e => props.handleChange(e)}>
                        <option key='-1' value=''></option>
                        {
                            Object.values(props.state.models['groups']).map((value, index) => (
                            <option key={index} value={value.id}>{value.name}</option>
                            ))
                        }
                        </Form.Select>
                        :
                        <Form.Control id={'input' + key} name={key} value={selected_model[key]} onChange={e => props.handleChange(e)} />
                    }
                    </Form.Group>
                </Col>
                </Row>
            ))
            }
        </Modal.Body>
        <Modal.Footer>
            { selected_model.is_update && <Button variant="danger" disabled={props.state.is_loading} onClick={props.deleteModel}>Delete</Button> }
            <Button variant="primary" disabled={props.state.is_loading} onClick={ selected_model.is_update ? props.updateModel : props.createModel}>
            { selected_model.is_update ? 'Save' : 'Create' }
            </Button>
        </Modal.Footer>
        </Modal>
  )
}
export default MatricesForm;


