import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { upperCaseWords, timestampToDate } from '../../utils';


function cleanModel(model) {
    let cleanModel = {
        id: model.id,
        internal_id: model.internal_id,
        work_order_id: model.work_order_id,
        metadata: {
            status: model.metadata.status,
            group: model.metadata.group,
            matrix: model.metadata.matrix,
            name: model.metadata.name,
            customer_lot_number: model.metadata.customer_lot_number,
            sample_description: model.metadata.sample_description,
            turnaround_time: model.metadata.turnaround_time,
            completed_at: model.metadata.completed_at,
            issued_at: model.metadata.issued_at,
            lab_notes: model.metadata.lab_notes,
            qa_notes: model.metadata.qa_notes,
            additional_report_notes: model.metadata.additional_report_notes,
            revision_history: model.metadata.revision_history,
            for_release: model.metadata.for_release !== undefined ? model.metadata.for_release : false,
            revision_number: model.metadata.revision_number !== undefined ? model.metadata.revision_number : "00"
        },
        image_one: model.image_one,
        image_two: model.image_two,
        is_update: model.is_update
    }
    if (model.id.toString().length > 0) {
        cleanModel['tests'] = model.tests;
    }
    return cleanModel;
}

const SamplesForm = (props) => {
    const selected_model = cleanModel(props.state.selected_model);

    return (
        <Modal show={selected_model !== null} onHide={props.resetSelectedModel}>
            <Modal.Header closeButton>
                <Modal.Title>{ selected_model.is_update ? 'Edit' : 'Add' }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                Object.keys(selected_model).map((key, index) => (
                    ['id', 'is_update'].includes(key) ? null :
                    "metadata" === key ? 
                    Object.keys(selected_model['metadata']).filter(key => key !== 'for_release').map((key, index) => (
                        <Row key={index}>
                            <Col xs={12} md={12}>
                                <Form.Group className="mb-3">
                                <Form.Label htmlFor={'input' + key} style={{'fontSize': '12px', 'marginBottom': '5px', 'textTransform': 'uppercase', 'color': '#9a9a9a'}}>{upperCaseWords((key.split('_').length > 1 && key.split('_')[1] === 'id') ? [key.split('_')[0]] : key.split('_'))}</Form.Label>
                                {
                                    "status" === key ?
                                    <Form.Select id={'input' + key} name={key} value={selected_model['metadata'][key]} onChange={e => props.handleChange(e, null, 'metadata')}>
                                        <option key='SAMPLE RECEIVED' value='SAMPLE RECEIVED'>SAMPLE RECEIVED</option>
                                        <option key='TESTING INITIATED' value='TESTING INITIATED'>TESTING INITIATED</option>
                                        <option key='TESTING COMPLETE' value='TESTING COMPLETE'>TESTING COMPLETE</option>
                                        <option key='REPORT COMPLETE' value='REPORT COMPLETE'>REPORT COMPLETE</option>
                                        <option key='REPORT RELEASED' value='REPORT RELEASED'>REPORT RELEASED</option>
                                    </Form.Select>
                                    :
                                    "group" === key ?
                                    <Form.Select id={'input' + key} name={key} value={selected_model['metadata'][key]} onChange={e => props.handleChange(e, null, 'metadata')}>
                                    {
                                        Object.values(props.state.models['groups']).map((value, index) => (
                                            <option key={index} value={value.name.toUpperCase()}>{value.name.toUpperCase()}</option>
                                        ))
                                    }
                                    </Form.Select>
                                    :
                                    "matrix" === key ?
                                    <Form.Select id={'input' + key} name={key} value={selected_model['metadata'][key]} onChange={e => props.handleChange(e, null, 'metadata')}>
                                    {
                                        Object.values(props.state.models['matrices'].filter(matrix => props.state.models['groups'].find(group => group.id === matrix.group_id && group.name.toUpperCase() === selected_model['metadata']['group']))).map((value, index) => (
                                            <option key={index} value={value.name.toUpperCase()}>{value.name.toUpperCase()}</option>
                                        ))
                                    }
                                    </Form.Select>
                                    :
                                    "turnaround_time" === key ?
                                    <Form.Select id={'input' + key} name={key} value={selected_model['metadata'][key]} onChange={e => props.handleChange(e, null, 'metadata')}>
                                        <option key='STANDARD' value='STANDARD'>STANDARD</option>
                                        <option key='RUSH-24HR' value='RUSH-24HR'>RUSH-24HR</option>
                                        <option key='RUSH-48HR' value='RUSH-48HR'>RUSH-48HR</option>
                                        <option key='RUSH-72HR' value='RUSH-72HR'>RUSH-72HR</option>
                                        <option key='CUSTOM (SEE NOTES)' value='CUSTOM (SEE NOTES)'>CUSTOM (SEE NOTES)</option>
                                    </Form.Select>
                                    :
                                    ["issued_at", "completed_at"].includes(key) ?
                                    <Form.Control type="date" id={'input' + key} name={key} value={timestampToDate(selected_model['metadata'][key])} onChange={e => props.handleChange(e, null, 'metadata')} />
                                    :
                                    ["sample_description", "lab_notes", "qa_notes", "revision_history", "additional_report_notes"].includes(key) ?
                                    <Form.Control as="textarea" rows={3} id={'input' + key} name={key} value={selected_model['metadata'][key]} onChange={e => props.handleChange(e, null, 'metadata')} />
                                    :
                                    "revision_number" === key ?
                                    <Form.Select id={'input' + key} name={key} value={selected_model['metadata'][key]} onChange={e => props.handleChange(e, null, 'metadata')}>
                                        <option key='00' value='00'>00</option>
                                        <option key='01' value='01'>01</option>
                                        <option key='02' value='02'>02</option>
                                        <option key='03' value='03'>03</option>
                                        <option key='04' value='04'>04</option>
                                    </Form.Select>
                                    :
                                    <Form.Control id={'input' + key} name={key} value={selected_model['metadata'][key]} onChange={e => props.handleChange(e, null, 'metadata')} />
                                }
                                </Form.Group>
                            </Col>
                        </Row>
                    )) :
                    <Row key={index}>
                        <Col xs={12} md={12}>
                            <Form.Group className="mb-3">
                            <Form.Label htmlFor={'input' + key} style={{'fontSize': '12px', 'marginBottom': '5px', 'textTransform': 'uppercase', 'color': '#9a9a9a'}}>{upperCaseWords((key.split('_').length > 1 && key.split('_')[0] !== 'internal' && key.split('_')[1] === 'id') ? [key.split('_')[0]] : key.split('_'))}</Form.Label>
                            {
                                "work_order_id" === key ?
                                <Form.Select id={'input' + key} name={key} value={selected_model[key]} onChange={e => props.handleChange(e)}>
                                <option key='-1' value=''></option>
                                {
                                    Object.values(props.state.models['work_orders']).map((value, index) => (
                                        <option key={index} value={value.id}>{value.internal_id}</option>
                                    ))
                                }
                                </Form.Select>
                                :
                                "internal_id" === key ?
                                <Form.Control id={'input' + key} name={key} value={selected_model[key]} onChange={e => props.handleChange(e)} />
                                :
                                ["image_one", "image_two"].includes(key) ?
                                selected_model[key]["base_64"] !== null ?
                                <>
                                    <br/><img style={{'width': '50%'}} src={selected_model[key]["base_64"]} />
                                    <br/><Button style={{'marginTop': '6px'}} variant="danger" onClick={e => props.deleteImage(selected_model[key], key)}>Delete Image</Button>
                                </>
                                :
                                <><br/><input type='file' disabled={props.state.is_loading} id={'input' + key} name={key} className='custom-file-input' onChange={e => props.uploadImage(e)} /></>
                                :
                                "tests" === key ?
                                <div id={'input' + key} >
                                    {
                                        selected_model[key].map((test, index) => (
                                            <div key={`${test.sample_id}_${index}`}>
                                                <Form.Label htmlFor={'inputTestId' + index} style={{'fontSize': '12px', 'marginBottom': '5px', 'textTransform': 'uppercase', 'color': '#9a9a9a'}}>Test Id</Form.Label>
                                                <Form.Select id={'inputTestId' + index} name={'tests'} value={selected_model[key][index]['test_template_id'] === '' ? '-1' : selected_model[key][index]['test_template_id']} onChange={e => props.handleChange(e, index, 'test_template_id')} >
                                                    <option key='-1' value=''></option>
                                                    {
                                                        Object.values(props.state.models['test_templates']).map((value, test_templates_index) => (
                                                            <option key={test_templates_index} value={value.id}>{value.internal_id}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                                <Form.Label htmlFor={'inputMetadata' + index} style={{'fontSize': '12px', 'marginBottom': '5px', 'textTransform': 'uppercase', 'color': '#9a9a9a'}}>Results</Form.Label>
                                                <Form.Control as="textarea" rows={3} id={'inputMetadata' + index} name={'tests'} value={selected_model[key][index]['metadata']} onChange={e => props.handleChange(e, index, 'metadata')} />
                                                <br/>
                                            </div>
                                        ))
                                    }
                                    <Button style={{'marginTop': '6px'}} variant="primary" disabled={props.state.is_loading} onClick={e => props.addTestToSample(e)}>Add Test</Button>
                                </div>
                                :
                                <Form.Control id={'input' + key} name={key} value={selected_model[key]} onChange={e => props.uploadImage(e)} />
                            }
                            </Form.Group>
                        </Col>
                    </Row>
                ))
                }
                <Row>
                    <Col xs={12} md={12}>
                        <Form.Group className="mb-3">
                            <Form.Check type="checkbox" id={'inputfor_release'} name="for_release" label="Marked for release" checked={selected_model['metadata']['for_release']} onChange={e => props.handleChange(e, null, 'metadata')} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                { selected_model.is_update && <Button variant="danger" disabled={props.state.is_loading} onClick={props.deleteModel}>Delete</Button> }
                { selected_model.is_update && <Button variant="secondary" disabled={props.state.is_loading || selected_model['tests'].length == 0} onClick={props.generateReport}>Generate Report</Button> }
                <Button variant="primary" disabled={props.state.is_loading} onClick={ selected_model.is_update ? props.updateModel : props.createModel}>
                { selected_model.is_update ? 'Save' : 'Create' }
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default SamplesForm;


