import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

function cleanModels(models) {
  let clean_models = models.map(model => {
    return {
      id: model.id,
      internal_id: model.internal_id,
      category: model.category,
      classification: model.classification,
      assay: model.assay,
      components: model.components,
      standards: model.standards,
      minimum_value: model.minimum_value,
      minimum_unit: model.minimum_unit,
      published: model.published
    }
  });
  clean_models = clean_models.sort((a, b) => b.internal_id.replace(/\D/g,'') - a.internal_id.replace(/\D/g,''));
  return clean_models;
}

const columns = [{
  dataField: 'internal_id',
  text: 'ID',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'category',
  text: 'Category',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'classification',
  text: 'Classification',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'assay',
  text: 'Assay',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'components',
  text: 'Components',
  sort: false,
  classes: 'table-cell'
}, {
  dataField: 'standards',
  text: 'Standards',
  sort: false,
  classes: 'table-cell'
}, {
  dataField: 'minimum_value',
  text: 'Minimum Value',
  sort: false,
  classes: 'table-cell'
}, {
  dataField: 'minimum_unit',
  text: 'Unit',
  sort: false,
  classes: 'table-cell'
}, {
  dataField: 'published',
  text: 'Is Published',
  sort: false,
  classes: 'table-cell'
}];

const TestTemplatesTable = (props) => {
  const test_templates = cleanModels(props.state.models[props.state.tab]);
    
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (!props.state.is_loading) {
        props.setSelectedModel(e, props.state.tab, rowIndex, row.id);
      }
    }
  };
  
  return (
    <BootstrapTable keyField='id' data={ test_templates } columns={ columns } rowEvents={ rowEvents } bootstrap4 />
  )
}
export default TestTemplatesTable;