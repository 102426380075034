import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { fetchTestsForSample, updateTest } from '../api';

function BulkTestUpload(props) {
  const [samples, setSamples] = useState(props.state['models']['samples']);
  const [testTemplates, setTestTemplates] = useState(props.state['models']['test_templates']);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [hover, setHover] = useState(false);
  const [results, setResults] = useState({ updated: [], errors: [], alreadyAdded: [], fileErrors: [] });

  const handleUpload = async (files) => {
    setLoading(true);

    const internalIdToIdMapping = testTemplates.reduce((map, item) => {
      map['T' + String(item.internal_id)] = item.id;
      return map;
    }, {});

    const samplesMapping = {};

    for (let file of files) {
      if (file.name.endsWith('.txt')) {
        const rawText = await file.text();
        const fullId = file.name.split('.')[0].split('_')[0];

        if (!samplesMapping[fullId]) {
          samplesMapping[fullId] = rawText;
        } else {
          samplesMapping[fullId] += "$\t$\t$\t$\t$\t$\n" + rawText;
        }
      }
    }

    for (const [fullId, metadata] of Object.entries(samplesMapping)) {
      const [testInternalId, internalSampleId] = fullId.split('-');

      const sample = samples.find(sample => sample.internal_id === internalSampleId);

      if (sample) {
        const tests = await fetchTestsForSample(sample);
        const test = tests.find(test => test.test_template_id === internalIdToIdMapping[testInternalId]);
        if ((typeof test.metadata === 'object' && typeof test.metadata !== null) || test.metadata.length === 0) {
          test.metadata = metadata;
          updateTest(test);
        }
      } else {
        test.status = "Invalid Internal Id for Sample";
      }
    }
        
    setLoading(false);
    setShowModal(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUpload,
    accept: {
      'text/plain': []
    },
  });

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div style={{'display': 'inline'}}>
      <Button onClick={handleOpenModal} style={{'marginBottom': '1rem', 'marginRight': '1rem'}}>Upload Bulk Tests</Button>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Bulk Tests</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <>
              <div
                {...getRootProps()}
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
                style={{
                  width: '100%', padding: '20px', textAlign: 'center',
                  border: '2px dashed #999', borderRadius: '5px',
                  backgroundColor: hover ? '#007BFF' : '#f5f5f5',
                  color: hover ? 'white' : 'black',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s'
                }}
              >
                <input {...getInputProps()} directory="" webkitdirectory="" mozdirectory="" />
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Drag 'n' drop a folder here, or click to select a folder</span>
              </div>
              {/* <div>
                <h4>Results:</h4>
                <p>Updated: {results.updated.join(', ')}</p>
                <p>Errors: {results.errors.join(', ')}</p>
                <p>Already Added: {results.alreadyAdded.join(', ')}</p>
                <p>File Errors: {results.fileErrors.join(', ')}</p>
              </div> */}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BulkTestUpload;
