import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Col, Table } from "react-bootstrap";
import { fetchInvoice } from "../api";

function GenerateInvoice(props) {
  const twoWeeksAgo = new Date(new Date().setDate(new Date().getDate() - 14))
    .toISOString()
    .split("T")[0];
  const today = new Date().toISOString().split("T")[0];

  const customers = props.state["models"]["customers"];

  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [startDate, setStartDate] = useState(twoWeeksAgo);
  const [endDate, setEndDate] = useState(today);
  const [showModal, setShowModal] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);

  useEffect(() => {
    if (customers.length > 0) {
      setSelectedCustomer(customers[0].id);
    }
  }, [customers]);

  const handleCustomerChange = (e) => {
    setSelectedCustomer(e.target.value);
  };

  const handleOpenModal = () => {
    setInvoiceData([]);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
  };

  const handleSubmit = async () => {
    const invoices = await fetchInvoice(selectedCustomer, startDate, endDate);
    const testTemplates = props.state["models"]["test_templates"];

    const augmentedInvoices = invoices.map((inv) => {
      const template = testTemplates.find((t) => t.id === inv.test_template_id);
      return {
        ...inv,
        created_at: formatDate(inv.created_at),
        category: template?.category,
        classification: template?.classification,
        assay: template?.assay,
        components: template?.components,
      };
    });

    setInvoiceData(augmentedInvoices);
  };

  useEffect(() => {
    if (new Date(startDate) > new Date(endDate)) {
      setEndDate(startDate);
    }
  }, [startDate, endDate]);

  return (
    <div>
      <Button onClick={handleOpenModal} style={{ marginRight: "1rem" }}>
        Generate Invoice
      </Button>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Generate Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Col} controlId="formGridCustomer">
              <Form.Label>Customer</Form.Label>
              <Form.Control
                as="select"
                value={selectedCustomer}
                onChange={handleCustomerChange}
              >
                {customers.map((customer, index) => (
                  <option key={index} value={customer.id}>
                    {customer.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridStartDate">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                max={today}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridEndDate">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                min={startDate}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
        {invoiceData.length > 0 && 
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Created At</th>
                <th>Sample ID</th>
                <th>Category</th>
                <th>Classification</th>
                <th>Assay</th>
                <th>Components</th>
              </tr>
            </thead>
            <tbody>
              {invoiceData.map((inv, index) => (
                <tr key={index}>
                  <td>{inv.created_at}</td>
                  <td>{inv.sample_id}</td>
                  <td>{inv.category}</td>
                  <td>{inv.classification}</td>
                  <td>{inv.assay}</td>
                  <td>{inv.components}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        }
      </Modal>
    </div>
  );
}

export default GenerateInvoice;
