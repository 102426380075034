import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from './pages/Home';
import Report from './pages/Report';
import Testing from './pages/Testing';
import Submission from './pages/Submission';
import CheckIn from './pages/CheckIn';
import { LoadingProvider } from './contexts/LoadingContext';
import GlobalSpinner from './components/GlobalSpinner';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <LoadingProvider>
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/samples/:id/report" element={<Report />} />
          <Route path="/testing" element={<Testing />} />
          <Route path="/checkin/:id" element={<CheckIn />} />
          <Route path="/submissions/:id" element={<Submission />} />
          <Route path='*' element={<Navigate to='/testing' />} />
        </Routes>
      </BrowserRouter>
      <GlobalSpinner />
    </div>
  </LoadingProvider>
);

// NOTE: Removed StrictMode because react-bootstrap-table-next was throwing UNSAFE_componentWillReceiveProps warning
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
