import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { upperCaseWords } from '../../utils';

const ContactsForm = (props) => {
  return (
    <Modal show={props.state.selected_model !== null} onHide={props.resetSelectedModel}>
        <Modal.Header closeButton>
            <Modal.Title>{ props.state.selected_model.is_update ? 'Edit' : 'Add' }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
            Object.keys(props.state.selected_model).map((key, index) => (
                ['id', 'created_at', 'updated_at', 'is_update'].includes(key) ? null :
                <Row key={index}>
                    <Col xs={12} md={12}>
                        <Form.Group className="mb-3">
                        <Form.Label htmlFor={'input' + key} style={{'fontSize': '12px', 'marginBottom': '5px', 'textTransform': 'uppercase', 'color': '#9a9a9a'}}>{upperCaseWords([key.split('_')[0]])}</Form.Label>
                        {
                            key === "customer_id" ?
                            <Form.Select id={'input' + key} name={key} value={props.state.selected_model[key]} onChange={e => props.handleChange(e)}>
                                <option key='-1' value=''></option>
                                {
                                    Object.values(props.state.models['customers']).map((value, index) => (
                                        <option key={index} value={value.id}>{value.name}</option>
                                    ))
                                }
                            </Form.Select>
                            :
                            key === "role" ?
                            <Form.Select id={'input' + key} name={key} value={props.state.selected_model[key]} onChange={e => props.handleChange(e)}>
                                <option key='ADMIN' value='ADMIN'>Admin</option>
                                <option key='MEMBER' value='MEMBER'>Member</option>
                            </Form.Select>
                            :
                            <Form.Control id={'input' + key} name={key} value={props.state.selected_model[key]} onChange={e => props.handleChange(e)} />
                        }
                        </Form.Group>
                    </Col>
                </Row>
            ))
            }
        </Modal.Body>
        <Modal.Footer>
            { props.state.selected_model.is_update && <Button variant="danger" disabled={props.state.is_loading} onClick={props.deleteModel}>Delete</Button> }
            <Button variant="primary" disabled={props.state.is_loading} onClick={ props.state.selected_model.is_update ? props.updateModel : props.createModel}>
            { props.state.selected_model.is_update ? 'Save' : 'Create' }
            </Button>
        </Modal.Footer>
    </Modal>
  )
}
export default ContactsForm;
