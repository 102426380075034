import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

function renderGroupName(groupId, groups) {
    const group = groups.find(group => group.id === groupId);
    return group ? group.name : 'N/A';
}

function renderMatrixName(matrixId, matrices) {
    const matrix = matrices.find(matrix => matrix.id === matrixId);
    return matrix ? matrix.name : 'N/A';
}

function renderTestTemplates(testTemplateIds, testTemplates) {
    return testTemplateIds.map(id => {
        const template = testTemplates.find(testTemplate => testTemplate.id === id);
        return template ? template.internal_id + " " + template.assay : 'N/A';
    }).join(', ');
}

function renderCustomers(customerIds, customers) {
    return customerIds.map(id => {
        const customer = customers.find(customer => customer.id === id);
        return customer ? customer.name : 'N/A';
    }).join(', ');
}

function cleanRules(rules, groups, matrices, testTemplates, customers) {
    return rules.map(rule => {
        return {
            id: rule.id,
            group: renderGroupName(rule.group_id, groups),
            matrix: renderMatrixName(rule.matrix_id, matrices),
            testTemplates: renderTestTemplates(rule.test_template_ids, testTemplates),
            customers: renderCustomers(rule.customer_ids, customers),
            additionalReportNotes: rule.additional_report_notes,
            reportDescription: rule.report_description
        }
    });
}

const columns = [{
  dataField: 'group',
  text: 'Group',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'matrix',
  text: 'Matrix',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'testTemplates',
  text: 'Test Templates',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'customers',
  text: 'Customers',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'additionalReportNotes',
  text: 'Additional Report Notes',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'reportDescription',
  text: 'Report Description',
  sort: true,
  classes: 'table-cell'
}];

const RulesTable = (props) => {
    const rules = cleanRules(props.state.models[props.state.tab], props.state.models['groups'], props.state.models['matrices'], props.state.models['test_templates'], props.state.models['customers']);

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            if (!props.state.is_loading) {
                props.setSelectedModel(e, props.state.tab, rowIndex, row.id);
            }
        }
    };

    return (
        <BootstrapTable keyField='id' data={ rules } columns={ columns } rowEvents={ rowEvents } bootstrap4 />
    )
}

export default RulesTable;
