import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { upperCaseWords } from '../../utils';

function cleanModel(model) {
  return {
    id: model.id,
    internal_id: model.internal_id,
    category: model.category,
    classification: model.classification,
    assay: model.assay,
    components: model.components,
    standards: model.standards,
    minimum_value: model.minimum_value,
    minimum_unit: model.minimum_unit,
    published: model.published,
    is_update: model.is_update
  }
}

const TestTemplateForm = (props) => {
  const selected_model = cleanModel(props.state.selected_model);

  return (
    <Modal show={selected_model !== null} onHide={props.resetSelectedModel}>
        <Modal.Header closeButton>
            <Modal.Title>{ selected_model.is_update ? 'Edit' : 'Add' }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
            Object.keys(selected_model).map((key, index) => (
                ['id', 'created_at', 'updated_at', 'is_update'].includes(key) ? null :
                <Row key={index}>
                <Col xs={12} md={12}>
                    <Form.Group className="mb-3">
                    <Form.Label htmlFor={'input' + key} style={{'fontSize': '12px', 'marginBottom': '5px', 'textTransform': 'uppercase', 'color': '#9a9a9a'}}>{upperCaseWords(key.split('_'))}</Form.Label>
                    {["components", "standards"].includes(key) ?
                      <Form.Control as="textarea" rows={4} id={'input' + key} name={key} value={selected_model[key]} onChange={e => props.handleChange(e)} />
                    :
                    ["published"].includes(key) ?
                      <Form.Select id={'input' + key} name={key} value={selected_model[key]} onChange={e => props.handleChange(e)}>
                        <option key={0} value={true}>{'true'}</option>
                        <option key={1} value={false}>{'false'}</option>
                      </Form.Select>
                    :
                      <Form.Control id={'input' + key} name={key} value={selected_model[key]} onChange={e => props.handleChange(e)} />
                    }
                    </Form.Group>
                </Col>
                </Row>
            ))
            }
        </Modal.Body>
        <Modal.Footer>
            { selected_model.is_update && <Button variant="danger" disabled={props.state.is_loading} onClick={props.deleteModel}>Delete</Button> }
            <Button variant="primary" disabled={props.state.is_loading} onClick={ selected_model.is_update ? props.updateModel : props.createModel}>
            { selected_model.is_update ? 'Save' : 'Create' }
            </Button>
        </Modal.Footer>
        </Modal>
  )
}
export default TestTemplateForm;