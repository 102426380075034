import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { upperCaseWords } from '../../utils';
import Select from 'react-select';

const RulesForm = (props) => {
    const handleSelectChange = (selectedOptions, meta) => {
        const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
        props.handleChange({
            target: {
                name: meta.name,
                value: values
            }
        });
    }
    
    return (
        <Modal show={props.state.selected_model !== null} onHide={props.resetSelectedModel}>
            <Modal.Header closeButton>
                <Modal.Title>{ props.state.selected_model.is_update ? 'Edit Rule' : 'Add Rule' }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                Object.keys(props.state.selected_model).map((key, index) => (
                    ['id', 'created_at', 'updated_at', 'is_update'].includes(key) ? null :
                    <Row key={index}>
                    <Col xs={12} md={12}>
                        <Form.Group className="mb-3">
                        <Form.Label htmlFor={'input' + key} style={{'fontSize': '12px', 'marginBottom': '5px', 'textTransform': 'uppercase', 'color': '#9a9a9a'}}>{upperCaseWords(key.split('_'))}</Form.Label>
                        {
                            "group_id" === key ?
                            <Form.Select id={'input' + key} name={key} value={props.state.selected_model[key]} onChange={e => props.handleChange(e)}>
                            <option key='-1' value=''></option>
                            {
                                Object.values(props.state.models['groups']).map((value, index) => (
                                <option key={index} value={value.id}>{value.name}</option>
                                ))
                            }
                            </Form.Select>
                            :
                            "matrix_id" === key ?
                            <Form.Select id={'input' + key} name={key} value={props.state.selected_model[key]} onChange={e => props.handleChange(e)}>
                            <option key='-1' value=''></option>
                            {
                                Object.values(props.state.models['matrices']).map((value, index) => (
                                    <option key={index} value={value.id}>{value.name}</option>
                                ))
                            }
                            </Form.Select>
                            :
                            "test_template_ids" === key ?
                            <Form.Group controlId={'input' + key}>
                                <Form.Label>{upperCaseWords(key.split('_'))}</Form.Label>
                                <Select
                                    isMulti
                                    name={key}
                                    options={Object.values(props.state.models['test_templates']).map(value => ({
                                        value: value.id,
                                        label: value.internal_id + " " + value.assay
                                    }))}
                                    onChange={(selectedOptions) => handleSelectChange(selectedOptions, { name: key })}
                                    value={props.state.selected_model[key].map(id => ({
                                        value: id,
                                        label: props.state.models['test_templates'].find(test_template => test_template.id === id).internal_id + " " + props.state.models['test_templates'].find(test_template => test_template.id === id).assay
                                    }))}
                                />
                            </Form.Group>
                            :
                            "customer_ids" === key ?
                            <Form.Group controlId={'input' + key}>
                                <Form.Label>{upperCaseWords(key.split('_'))}</Form.Label>
                                <Select
                                    isMulti
                                    name={key}
                                    options={Object.values(props.state.models['customers']).map(value => ({
                                        value: value.id,
                                        label: value.name
                                    }))}
                                    onChange={(selectedOptions) => handleSelectChange(selectedOptions, { name: key })}
                                    value={props.state.selected_model[key].map(id => ({
                                        value: id,
                                        label: props.state.models['customers'].find(customer => customer.id === id).name
                                    }))}
                                />
                            </Form.Group>
                            :
                            <Form.Control id={'input' + key} name={key} value={props.state.selected_model[key]} onChange={e => props.handleChange(e)} />
                        }
                        </Form.Group>
                    </Col>
                    </Row>
                ))
                }
            </Modal.Body>
            <Modal.Footer>
                { props.state.selected_model.is_update && <Button variant="danger" disabled={props.state.is_loading} onClick={props.deleteModel}>Delete</Button> }
                <Button variant="primary" disabled={props.state.is_loading} onClick={ props.state.selected_model.is_update ? props.updateModel : props.createModel}>
                { props.state.selected_model.is_update ? 'Save' : 'Create' }
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default RulesForm;
