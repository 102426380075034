import Resizer from "react-image-file-resizer";
import CryptoJS from 'crypto-js';

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
        file,
        768,
        768,
        "JPEG",
        100,
        0,
        (uri) => {
            resolve(uri);
        },
        "base64",
        512,
        512
    );
});

function upperCaseWords(words) {
    for (var i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join(" ");
}

const zeroPad = (num, places) => String(num).padStart(places, '0')

function timestampToDate(timestamp) {
  const date = new Date(timestamp);
  return date.getUTCFullYear() + "-" + zeroPad(date.getUTCMonth() + 1, 2) + "-" + zeroPad(date.getUTCDate(), 2);
}

const secretKey = process.env.REACT_APP_ENCRYPTION_KEY;

const toUrlSafeBase64 = (base64) => {
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

// URL-safe Base64 decoding
const fromUrlSafeBase64 = (urlSafeBase64) => {
  let base64 = urlSafeBase64.replace(/-/g, '+').replace(/_/g, '/');
  while (base64.length % 4) {
      base64 += '=';
  }
  return base64;
};

const encrypt = (text) => {
  if (typeof text !== 'string') {
      text = String(text);
  }
  const ciphertext = CryptoJS.AES.encrypt(text, secretKey).toString();
  const urlSafeCiphertext = toUrlSafeBase64(ciphertext);
  console.log('Encrypted (URL-safe) text:', urlSafeCiphertext); // Debug log
  return urlSafeCiphertext;
};

const decrypt = (urlSafeCiphertext) => {
  try {
      const base64Ciphertext = fromUrlSafeBase64(urlSafeCiphertext);
      const bytes = CryptoJS.AES.decrypt(base64Ciphertext, secretKey);
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      console.log('Decrypted text:', originalText); // Debug log
      return originalText;
  } catch (error) {
      console.error('Decryption error:', error); // Debug log
      return null;
  }
};

export { resizeFile, upperCaseWords, timestampToDate, encrypt, decrypt };