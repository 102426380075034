import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

function renderAddress(id, addresses) {
    const address = addresses.find(address => address.id === id);
    return address.address_1 + ' ' + address.address_2;
}

function renderContact(id, contacts) {
    const contact = contacts.find(contact => contact.id === id);
    return contact.first_name + ' ' + contact.last_name;
}

function renderAccount(id, billing_accounts) {
  const account = billing_accounts.find(billing_account => billing_account.id === id);
  return account.name;
}

function cleanModels(models, addresses, contacts, billing_accounts) {
  return models.map(model => {
    return {
      id: model.id,
      name: model.name,
      attention: model.attention,
      account: renderAccount(model.account_id, billing_accounts),
      contact: renderContact(model.contact_id, contacts),
      address: renderAddress(model.address_id, addresses),
      active: model.active
    }
  });
}

const columns = [{
  dataField: 'name',
  text: 'Name',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'attention',
  text: 'Attention',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'account',
  text: 'Billing Account',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'contact',
  text: 'Contact',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'address',
  text: 'Address',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'active',
  text: 'Active',
  sort: true,
  classes: 'table-cell'
}];

const CustomersTable = (props) => {
  const customers = cleanModels(props.state.models[props.state.tab], props.state.models['addresses'], props.state.models['contacts'], props.state.models['billing_accounts']);

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (!props.state.is_loading) {
        props.setSelectedModel(e, props.state.tab, rowIndex, row.id);
      }
    }
  };
  
  return (
    <BootstrapTable keyField='name' data={ customers } columns={ columns } rowEvents={ rowEvents } bootstrap4 />
  )
}

export default CustomersTable;