import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

function renderCustomer(id, customers) {
  const customer = customers.find(customer => customer.id === id);
  if (customer == undefined) {
    return '';
  } else {
    return customer.name;
  }
}

function cleanModels(models, customers) {
  return models.map(model => {
    return {
      id: model.id,
      first_name: model.first_name,
      last_name: model.last_name,
      title: model.title,
      email_primary: model.email_primary,
      phone: model.phone,
      role: model.role,
      customer: renderCustomer(model.customer_id, customers),
    }
  });
}

const columns = [{
  dataField: 'first_name',
  text: 'First Name',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'last_name',
  text: 'Last Name',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'customer',
  text: 'Customer',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'title',
  text: 'Title',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'email_primary',
  text: 'Email',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'phone',
  text: 'Phone',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'role',
  text: 'Role',
  sort: true,
  classes: 'table-cell'
}];

const ContactsTable = (props) => {
  const contacts = cleanModels(props.state.models[props.state.tab], props.state.models['customers']);

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (!props.state.is_loading) {
        props.setSelectedModel(e, props.state.tab, rowIndex, row.id);
      }
    }
  };
  
  return (
    <BootstrapTable keyField='id' data={ contacts } columns={ columns } rowEvents={ rowEvents } bootstrap4 />
  )
}

export default ContactsTable;