import React, { Component } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import sha256 from 'crypto-js/sha256';

import './Report.css';
import { fetchAddress, fetchBillingAccount, fetchContact, fetchCustomer, fetchImagesForSample, fetchSample, fetchTestsForSample, fetchWorkOrder, htmlToPDF } from '../api';
import { timestampToDate, decrypt } from '../utils';

const cookies = new Cookies();
const MONTHS = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

function formatDateString(date_string) {
    if (typeof date_string == 'number') {
        date_string = timestampToDate(date_string);
    }
    return `${date_string.split('-')[2]}${MONTHS[parseInt(date_string.split('-')[1]) - 1]}${date_string.split('-')[0]}`;
}

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'logo': null,
      'signature': null,
    };
  }

  setStateForKey(key, value) {
    this.setState({ [key]: value })
  }

  getImageBase64 = (asset) => {
    const imgURL = process.env.PUBLIC_URL + `/${asset}.png`; // Path to your image
    fetch(imgURL)
      .then(res => res.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.setState({[asset]: reader.result});
        }
        reader.readAsDataURL(blob);
      });
  }

  componentDidMount = async () => {
    const sampleId = this.props.params['id'];
    const decryptedSampleId = decrypt(sampleId);
    let models = {};

    models['sample'] = await fetchSample({ id: decryptedSampleId });
    models['work_order'] = await fetchWorkOrder({'id': models['sample'].work_order_id});
    models['customer'] = await fetchCustomer({'id': models['work_order'].customer_id});
    models['address'] = await fetchAddress({'id': models['customer'].address_id});
    models['account'] = await fetchBillingAccount({'id': models['customer'].account_id});
    models['contact'] = await fetchContact({'id': models['customer'].contact_id});
    models['images'] = await fetchImagesForSample({ id: decryptedSampleId });
    models['tests'] = await fetchTestsForSample({ id: decryptedSampleId });

    this.getImageBase64('logo');
    this.getImageBase64('signature');

    let tables = [];
    for (const test of models['tests']) {
        const rows = test['metadata'].trim().split('\n');
        let table = [];
        for (let row of rows) {
            if (row.trim().replaceAll('\t', '').replaceAll('$', '').length === 0) {
                tables.push(table);
                table = [];
            } else {
                table.push(row.trim().split('\t'));
            }
        }
        tables.push(table);
    }
    models['tables'] = tables;
    this.setStateForKey('models', models);

    window.addEventListener('keydown', this.preventPrint);
  }

  preventPrint = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
      event.preventDefault();
      alert("Keyboard printing is disabled on this page. Please use the 'print' button in the upper left-hand corner of the webpage.");
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.preventPrint);
  }

  printDocument = async () => {

    var html = 
    `<html>
      <head>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
      </head>
      <style>
        hr {
          margin:1rem -15px; 
          height:1px; 
          background-color:#000;
        }
        
        h2 {
          margin:8px 0px;
        }
        
        h3 {
          margin:8px 0px;
        }
        
        h4 {
          margin:8px 0px;
        }
        
        h5 {
          margin:8px 0px;
        }
        
        div.lines table, div.lines th, div.lines td {
          border: 0px solid black;
          border-collapse: collapse;
          text-align: center;
        }
        
        div.lines table td:first-child {
          text-align: left;
        }
        
        div.lines th {
          text-align: center;
        }
        
        p {
          white-space: pre-line;
        }      

        body {
          margin: 0;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        
        code {
          font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
        }        
      </style>
      <body>
        ${document.getElementById('targetDiv').innerHTML}
      </body>
    </html>`;

    const response = await htmlToPDF(html, this.state.models.work_order.internal_id, this.state.models.sample.internal_id, formatDateString(this.state.models.work_order.received_at), formatDateString(this.state.models.sample.metadata.issued_at))
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const revision_number = this.state.models.sample.metadata.revision_number !== undefined ? this.state.models.sample.metadata.revision_number : "00"
    link.download = `${this.state.models.sample.internal_id}_CoA_rev${revision_number}.pdf`;
    link.click();
    window.URL.revokeObjectURL(url); 
  }

  render() {
    const { models, logo, signature } = this.state;

    return (
        <div>
            <button onClick={this.printDocument}>Print</button>
            { models === undefined ? 'Loading...' :
            <div id="targetDiv">
              <div className="container" style={{"maxWidth": "1140px"}}>
                  <div style={{"display": "block", "marginRight": "-15px", "marginLeft": "-15px"}}>
                      <div style={{"width": "66%", "display": "inline-block", "textAlign": "left", "verticalAlign": "middle"}}>
                          <h2>Certificate of Analysis</h2>
                      </div>
                      <div style={{"width": "33%", "display": "inline-block", "textAlign": "right", "verticalAlign": "middle"}}>
                          {logo && <img src={logo} style={{"width": "40%"}} />}
                      </div>
                  </div>

                  <hr/>
                  <div className="row">
                      <div style={{"width": "50%", "display": "flex", "justifyContent": "flex-start", "alignItems": "center"}}>
                          <h5>Customer Information</h5>
                      </div>
                      <div style={{"width": "50%", "display": "flex", "justifyContent": "flex-start", "alignItems": "center"}}>
                          <h5>Testing Facility</h5>
                      </div>
                  </div>
                  <div className="row">
                      <div style={{"width": "50%", "display": "flex", "justifyContent": "flex-start", "alignItems": "center"}}>
                          <table style={{"width": "100%"}}>
                              <tbody>
                                  <tr>
                                      <th>Client:</th>
                                      <td>{models.customer.name}</td>
                                  </tr>
                                  <tr>
                                      <th>Attention:</th>
                                      <td>{models.customer.attention}</td>
                                  </tr>
                                  <tr>
                                      <th>Address:</th>
                                      <td>{models.address.address_1}{models.address.address_2.length > 0 && `, ${models.address.address_2}`}</td>
                                  </tr>
                                  <tr>
                                      <th></th>
                                      <td>{`${models.address.city}, ${models.address.state} ${models.address.postal_code}`}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <div style={{"width": "50%", "display": "flex", "justifyContent": "flex-start", "alignItems": "center"}}>
                          <table style={{"width": "100%"}}>
                              <tbody>
                                  <tr>
                                      <th>Lab:</th>
                                      <td>Cora Science, LLC</td>
                                  </tr>
                                  <tr>
                                      <th>Address</th>
                                      <td>8000 Anderson Square, STE 113</td>
                                  </tr>
                                  <tr>
                                      <th></th>
                                      <td>Austin, Texas 78757</td>
                                  </tr>
                                  <tr>
                                      <th>Contact:</th>
                                      <td>info@corascience.com</td>
                                  </tr>
                                  <tr>
                                      <th></th>
                                      <td>(512) 856-5007</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                  <hr/>
                  <div className="row">
                      <div style={{"width": "50%", "display": "flex", "justifyContent": "flex-start", "alignItems": "center"}}>
                          <h5>Sample Image(s)</h5>
                      </div>
                      <div style={{"width": "50%", "display": "flex", "justifyContent": "flex-start", "alignItems": "center"}}>
                          <h5>Sample Information</h5>
                      </div>
                  </div>
                  <div className="row">
                      <div style={{"width": "50%", "display": "block", "textAlign": "center"}}>
                          <div style={{"display": "inline-block", "marginRight": "-15px", "marginLeft": "-15px"}}>
                          {
                              models.images.map((image, index) => (
                                  <div key={index} style={{"width": "200px", "display": "inline-block", "textAlign": "left", "verticalAlign": "middle", "margin": models.images.length > 1 ? "0 5px" : "0 auto"}}>
                                      <img src={image.base_64.replaceAll('"', '')} style={{"width": "100%"}}/>
                                  </div>
                              ))
                          }
                          </div>
                      </div>
                      <div style={{"width": "50%", "display": "flex", "justifyContent": "flex-start", "alignItems": "center"}}>
                          <table style={{"width": "100%"}}>
                              <tbody>
                                  <tr>
                                      <th>Name:</th>
                                      <td>{models.sample.metadata.name}</td>
                                  </tr>
                                  <tr>
                                      <th>Lot Number:</th>
                                      <td>{models.sample.metadata.customer_lot_number}</td>
                                  </tr>
                                  <tr>
                                      <th>Description:</th>
                                      <td>{models.sample.metadata.sample_description}</td>
                                  </tr>
                                  <tr>
                                      <th>Condition:</th>
                                      <td>Good</td>
                                  </tr>
                                  <tr>
                                      <th>Job ID:</th>
                                      <td>{models.work_order.internal_id}</td>
                                  </tr>
                                  <tr>
                                      <th>Sample ID:</th>
                                      <td>{models.sample.internal_id}</td>
                                  </tr>
                                  <tr>
                                      <th>Received:</th>
                                      <td>{formatDateString(models.work_order.received_at)}</td>
                                  </tr>
                                  <tr>
                                      <th>Completed:</th>
                                      <td>{'completed_at' in models.sample.metadata ? formatDateString(models.sample.metadata.completed_at) : 'MISSING'}</td>
                                  </tr>
                                  <tr>
                                      <th>Issued:</th>
                                      <td>{'issued_at' in models.sample.metadata ? formatDateString(models.sample.metadata.issued_at) : 'MISSING'}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                  <hr/>
                  <div className="row">
                      <div style={{"width": "100%", "display": "flex", "justifyContent": "left", "alignItems": "center"}}>
                          <h3>Test Results</h3>
                      </div>
                  </div>
                  {
                      models.tables.map((table, table_index) => (
                          <div key={table_index} style={{'marginBottom': '12px'}}>
                              <hr/>
                              <div className="row">
                                  <div style={{"width": "33%", "display": "flex", "justifyContent": "flex-start", "alignItems": "center"}}>
                                      <p style={{"marginBottom": "0px", "fontWeight": "700"}}>{table[0][0]}</p>
                                  </div>
                                  <div style={{"width": "34%", "display": "flex", "justifyContent": "flex-end", "alignItems": "center"}}>
                                      <p style={{"marginBottom": "0px", "fontWeight": "700"}}>Method Code: {table[0][3]}</p>
                                  </div>
                                  <div style={{"width": "33%", "display": "flex", "justifyContent": "flex-end", "alignItems": "center"}}>
                                      <p style={{"marginBottom": "0px", "fontWeight": "700"}}>Tested: {table[0][5].trim()}</p>
                                  </div>
                              </div>
                              <hr />
                              <div className="row">
                                  <div className="lines" style={{"width": "100%", "display": "flex", "justifyContent": "flex-start", "alignItems": "center"}}>
                                      <table style={{"width": "100%"}}>
                                          <tbody>
                                              {
                                                  table.slice(1).map((row, row_index) => (
                                                      <tr key={row_index}>
                                                          {
                                                              row.map((cell, cell_index) => (
                                                                  row_index === 0 ?
                                                                  <th key={cell_index}>{cell}</th>
                                                                  :
                                                                  <td key={cell_index}>{cell}</td>
                                                              ))
                                                          }
                                                      </tr>
                                                  ))
                                              }
                                          </tbody>
                                      </table>
                                  </div>
                              </div>
                          </div>
                      ))
                  }
                  <hr/>
                  <div className="row">
                      <div style={{"width": "100%", "display": "flex", "justifyContent": "left", "alignItems": "center"}}>
                          <h3>Additional Report Notes</h3>
                      </div>
                  </div>
                  <hr/>
                  <div className="row">
                      <div style={{"width": "100%", "display": "flex", "justifyContent": "flex-start", "alignItems": "center"}}>
                          <p style={{'margin': '0px'}}>{models.sample.metadata.additional_report_notes}</p>
                      </div>
                  </div>
                  <hr/>
                  <div className="row">
                      <div style={{"width": "100%", "display": "flex", "justifyContent": "left", "alignItems": "center"}}>
                          <h3>Revision History</h3>
                      </div>
                  </div>
                  <hr/>
                  <div className="row">
                      <div style={{"width": "100%", "display": "flex", "justifyContent": "flex-start", "alignItems": "center"}}>
                          <p style={{'margin': '0px'}}>{models.sample.metadata.revision_history}</p>
                      </div>
                  </div>
                  <hr/>
                  <div className="row">
                      <div style={{"width": "100%", "display": "flex", "justifyContent": "left", "alignItems": "center"}}>
                          <h3>Abbreviations</h3>
                      </div>
                  </div>
                  <hr/>
                  <div className="row">
                      <div style={{"width": "100%", "display": "flex", "justifyContent": "flex-start", "alignItems": "center"}}>
                          <p style={{'margin': '0px'}}>
                            <b> ID:</b> identification, 
                            <b> N/A:</b> not applicable, 
                            <b> LOQ:</b> limit of quantitation, 
                            <b> CFU:</b> colony forming units, 
                            <b> w/w%:</b> weight by weight percent, 
                            <b> mg:</b> milligrams, 
                            <b> g:</b> grams, 
                            <b> ug:</b> micrograms, 
                            <b> mL:</b> milliliters, 
                            <b> ND:</b> not detected, 
                            <b> &lt;LOQ:</b> below limit of quantitation, 
                            <b> NMT:</b> no more than, 
                            <b> NLT:</b> no less than, 
                            <b> UHPLC:</b> ultra-high performance liquid chromatography, 
                            <b> GC:</b> gas chromatography, 
                            <b> DAD:</b> diode array detection/detector, 
                            <b> MS:</b> mass spectroscopy/spectrometer, 
                            <b> ICP:</b> inductively coupled plasma, 
                            <b> ISO:</b> International Organization for Standardization, 
                            <b> USP:</b> United States Pharmacopeia
                        </p>
                      </div>
                  </div>
                  <hr/>
                  <div className="row">
                      <div style={{"width": "100%", "display": "flex", "justifyContent": "left", "alignItems": "center"}}>
                          <h3>Authorization</h3>
                      </div>
                  </div>
                  <hr/>
                  <div className="row">
                      <h6 style={{'margin': '0px', 'width': '100%'}}>This report has been authorized for release from Cora Science by:</h6>
                      <div style={{"width": "50%", "display": "flex", "justifyContent": "flex-start", "alignItems": "center"}}>
                          <table style={{"width": "100%"}}>
                              <tbody>
                                  <tr>
                                      <th>Signature:</th>
                                      {signature && <td><img src={signature} style={{"width": "50%"}} /></td>}
                                  </tr>
                                  <tr>
                                      <th>Name:</th>
                                      <td>Tyler West</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <div style={{"width": "50%", "display": "flex", "justifyContent": "flex-end", "alignItems": "center"}}>
                          <table style={{"width": "100%"}}>
                              <tbody>
                                  <tr>
                                      <th>Position:</th>
                                      <td>Laboratory Director</td>
                                  </tr>
                                  <tr>
                                      <th>Department:</th>
                                      <td>Management</td>
                                  </tr>
                                  <tr>
                                      <th>Date:</th>
                                      <td>{formatDateString(this.state.models.sample.metadata.issued_at)}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                  <div style={{"height": "100px"}}>
                  
                  </div>
              </div>   
            </div>   
            } 
        </div>  
    );
  }
}

export default withParams(Report);