import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

function renderAddress(id, addresses) {
    const address = addresses.find(address => address.id === id);
    return address.address_1 + ' ' + address.address_2;
}

function renderContact(id, contacts) {
    const contact = contacts.find(contact => contact.id === id);
    return contact.first_name + ' ' + contact.last_name;
}

function cleanModels(models, addresses, contacts) {
  return models.map(model => {
    return {
      id: model.id,
      name: model.name,
      payment_method: model.payment_method,
      contact: renderContact(model.contact_id, contacts),
      address: renderAddress(model.address_id, addresses)
    }
  });
}

const columns = [{
  dataField: 'name',
  text: 'Name',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'contact',
  text: 'Contact',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'address',
  text: 'Address',
  sort: true,
  classes: 'table-cell'
}, {
  dataField: 'payment_method',
  text: 'Payment Method',
  sort: true,
  classes: 'table-cell'
}];

const AccountsTable = (props) => {
  const samples = cleanModels(props.state.models[props.state.tab], props.state.models['addresses'], props.state.models['contacts']);

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (!props.state.is_loading) {
        props.setSelectedModel(e, props.state.tab, rowIndex, row.id);
      }
    }
  };
  
  return (
    <BootstrapTable keyField='name' data={ samples } columns={ columns } rowEvents={ rowEvents } bootstrap4 />
  )
}

export default AccountsTable;